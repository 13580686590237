import { addDoc, collection, doc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { getCurrentDate } from "../../helper/time";
import { firestoreStatic } from "../../static/firestore.static";
import { db, storage } from "../setup";

export async function updateFormData(id, data, overwrite) {
  if (!"result" in data) throw "Emission result not present";
  data["updatedAt"] = getCurrentDate();

  // File addition
  let files = data.file;
  if (files && typeof files !== "string") {
    data.file = files.map((file) => file.name ?? file);
  }
  console.log("Updating data (console.log in update.js firebase)", data);
  // Determine whether to overwrite the entire document or update it
  const docRef = doc(db, firestoreStatic.results, id);
  if (overwrite) {
    await setDoc(docRef, data);
  } else {
    await updateDoc(docRef, data);
  }

  // Handle file upload
  //only update the new files
  if (files)
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file && typeof file !== "string") {
        const reference = ref(storage, "formdata/" + id + "/" + file.name);
        console.log(await uploadBytes(reference, file), "uploaded file");
      }
    }
}
