import { collection, getDocs, query, where } from "firebase/firestore";
import { findCompany, findSite, openSites } from "../../helper/attribute";
import { setResults } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { db } from "../setup";
import { docsWithIds } from "../util";
import { scope3overwrite } from "../../helper/results";

export async function fetchResults(formName = undefined, batchSize = 30, plan = undefined) {
  let sites = openSites() || [];
  if (sites.length === 0) {
    store.dispatch(setResults([]));
    return;
  }

  if (plan) {
    //setting plan key
    sites = sites.filter((site) => {
      return findCompany(site.companyId)?.plan === plan;
    });
  }

  const collectionRef = collection(db, firestoreStatic.results);
  let results = [];

  // Iterate through the sites in batches
  for (let i = 0; i < sites.length; i += batchSize) {
    const batchSites = sites.slice(i, i + batchSize);

    const q = query(
      collectionRef,
      where(
        "siteId",
        "in",
        batchSites.map((c) => c.id)
      )
      // limit(batchSize) // Limit the number of results per batch
    );
    if (formName) {
      const q = query(
        collectionRef,
        where(
          "siteId",
          "in",
          batchSites.map((c) => c.id)
        ),
        where("name", "==", formName)
        // limit(batchSize) // Limit the number of results per batch
      );
    }

    const docs = await getDocs(q);
    const batchResults = docsWithIds(docs);
    results.push(...batchResults);
  }
  // await bulkDeleteResults(results.map((result) => result.id));

  store.dispatch(setResults(scope3overwrite(results)));
}
