import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
const { TextArea } = Input;

const AutoCompleteTextArea = ({ value, onChange, suggestions }) => {
  if (!suggestions) suggestions = ["sample 1", "sample 2"];
  const [options, setOptions] = useState(suggestions.map((value) => ({ value })));

  // const handleSearch = (searchText) => {
  //   const filteredOptions = suggestions.filter((suggestion) => suggestion.toLowerCase().includes(searchText.toLowerCase())).map((suggestion) => ({ value: suggestion }));

  //   // setOptions(filteredOptions.length ? filteredOptions : [{ value: searchText }]);
  // };

  const handleSelect = (selectedValue) => {
    onChange(selectedValue);
  };

  return (
    <AutoComplete options={options} onSelect={handleSelect} value={value} style={{ width: "100%" }}>
      <TextArea value={value} onChange={(e) => onChange(e.target.value)} autoSize style={{ width: "100%" }} />
    </AutoComplete>
  );
};

export default AutoCompleteTextArea;
