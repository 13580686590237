import React from "react";
import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";

import { commonChartOptions, EmptyChart } from "./charts.config";

function sortColumnsByAverage(data) {
  return data;
}

function StackedBarChart({
  data,
  type = "emission",
  hAxisDirection = 1,
  resultTitle = "Year",
}) {
  const chartData = [
    [
      "Year",
      ...getUniqueCombinedCategories(
        data,
        data.map((data) => data.Type)
      ),
    ],
  ];

  const navigate = useNavigate();
  const years = getUniqueYears(data);

  const emissionsByType = {};

  for (const item of data) {
    const { Type, ...emissionsByYear } = item;
    if (type === "emission") {
      if (Type.includes("Scope") || Type.includes("Total")) {
        continue;
      }
    } else if (type === "scope") {
      if (!(Type.includes("Scope") && Type.includes("Total"))) {
        continue;
      }
    }

    for (const year in emissionsByYear) {
      if (!isNaN(parseInt(year))) {
      }
      const emissions = parseFloat(emissionsByYear[year]) || 0;

      const combinedCategory = getCombinedCategory(
        Type,
        data.map((data) => data.Type)
      );
      if (chartData[0].includes(combinedCategory)) {
        emissionsByType[year] = emissionsByType[year] || {};
        emissionsByType[year][combinedCategory] =
          (emissionsByType[year][combinedCategory] || 0) + emissions;
      }
    }
  }

  for (const year of years) {
    const row = [year];

    for (const category of chartData[0].slice(1)) {
      row.push(emissionsByType[year]?.[category] || 0);
    }

    chartData.push(row);
  }
  let chartDataFormatted = removeZeroColumns(chartData);
  if (type === "scope")
    chartDataFormatted = formatScopeData(chartDataFormatted);
  if (chartDataFormatted?.length <= 1) return <EmptyChart />;

  function reverseArrayExceptFirst(arr) {
    return [arr[0]].concat(arr.slice(1).reverse());
  }
  chartDataFormatted = sortColumnsByAverage(chartDataFormatted);

  return (
    <>
      {/* <div>{JSON.stringify(chartData)}</div> */}
      <Chart
        {...commonChartOptions}
        chartType="ColumnChart"
        data={chartDataFormatted}
        options={{
          isStacked: true,
          title: "Emissions by " + resultTitle,
          hAxis: {
            title: resultTitle,
            direction: hAxisDirection,
          },
          vAxis: {
            title: "Emissions",
          },
          animation: {
            startup: true,
            easing: "linear",
            duration: 100,
          },
        }}
      />
    </>
  );
}

function getUniqueYears(data) {
  const yearsSet = new Set();

  for (const item of data) {
    const { Type, ...emissionsByYear } = item;

    if (Type.includes("Scope") || Type.includes("Total")) {
      continue;
    }

    for (const year in emissionsByYear) {
      yearsSet.add(year);
    }
  }

  return Array.from(yearsSet);
}

function getUniqueCombinedCategories(data, combinedCategories) {
  const combinedCategoriesSet = new Set();

  for (const item of data) {
    const { Type } = item;

    const combinedCategory = getCombinedCategory(Type, combinedCategories);

    // combinedCategoriesSet.add(combinedCategory);
    combinedCategoriesSet.add(Type);
  }

  return Array.from(combinedCategoriesSet);
}

function getCombinedCategory(type, combinedCategories) {
  return type;
  for (const combinedCategory of combinedCategories) {
    if (type.toLowerCase().includes(combinedCategory.toLowerCase())) {
      return combinedCategory;
    }
  }
  return type;
}

function removeZeroColumns(data) {
  let columnsToRemove = []; // Array to store the columns to be removed

  // Iterate over each column
  for (let col = 1; col < data[0].length; col++) {
    // Check if all values in the column are zero
    if (data.slice(1).every((row) => row[col] === 0)) {
      columnsToRemove.push(col);
    }
  }

  // Remove the columns from the data
  for (let row of data) {
    for (let col of columnsToRemove.sort((a, b) => b - a)) {
      row.splice(col, 1);
    }
  }

  return data;
}

function formatScopeData(data) {
  const modifiedTitles = data[0].map((x) =>
    x.replace("Total (", "").replace(")", "")
  );
  data[0] = modifiedTitles;
  return data;
}

export default StackedBarChart;
