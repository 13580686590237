import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Empty, Space, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../../appconfig/texts";
import { CompanyPageLink, SitePageLink } from "../../../components/Link/Link";
import List from "../../../components/list/list";
import Loading from "../../../components/loading";
import DNDTable from "../../../components/table/dndtable";
import { getColumnSearchProps, getDropdownFilterProps } from "../../../components/table/tableProps";
import { batchUpdateSites } from "../../../firebase/site/update";
import { catcher } from "../../../firebase/util";
import { canAddSite, findAdminsOfSite, findCompany, getUserSiteRole, isConsultant, isSuperAdmin } from "../../../helper/attribute";
import { removeDuplicates } from "../../../helper/wodash";
import { selectSites } from "../../../redux/features/appSlice";
import { routePath } from "../../../static/routes.static";
import AddSiteModal from "./add";

export default function Sites({ canAdd = true, filterByCompanyId }) {
  let sites = useSelector(selectSites);
  if (filterByCompanyId && sites) {
    sites = sites.filter((site) => site.companyId === filterByCompanyId);
  }
  const navigate = useNavigate();
  const [modals, setModals] = useState({ add: false, update: false });
  const [loading, setLoading] = useState(false);
  const setModal = (whichOne, status = new Date().getTime()) => {
    modals[whichOne] = status;
    setModals({ ...modals });
  };
  const columns = [
    {
      title: <T1 pos="sites.table.name" />,
      render: (_) => <SitePageLink id={_.id} main={true} />,
      width: 150,
      ...getColumnSearchProps("name", (_) => _.title),
    },
    {
      title: <T1 pos="sites.table.company" />,
      render: (_) => <CompanyPageLink id={_.companyId} />,
      width: 150,
      ...getColumnSearchProps("companyName", (_) => _.companyName),
    },
    {
      title: <T1 pos="sites.table.country" />,
      render: (_) => _.country,
      width: 150,
      ...getColumnSearchProps("country", (_) => _.country, true, sites),
    },
    {
      title: <T1 pos="sites.table.admins" />,
      render: (_) => (
        <>
          <List
            translate={"no"}
            arr={findAdminsOfSite(_).map((x) => x.firstName)}
            handleClick={(firstName) => {
              const user = findAdminsOfSite(_)?.find((x) => x.firstName === firstName);
              navigate(routePath.UserDetails(user?.id));
            }}
            max={10}
          ></List>
        </>
      ),
      width: 150,
    },
    {
      title: <T1 pos="sites.table.yourRole" />,
      render: (_) => getUserSiteRole(_),
      width: 200,
    },
  ];
  const handleUpdateSorting = async (sites_sorted) => {
    catcher(
      async () => {
        await batchUpdateSites(sites_sorted);
      },
      { setLoading }
    );
  };
  if (!sites) return <Loading />;

  return (
    <>
      <Space style={{ display: "flex" }}>
        <Typography.Title level={2}>{<T1 pos="sites.title" />}</Typography.Title>
        {canAddSite() && canAdd && <Button onClick={() => setModal("add")}>{<T1 pos="sites.addButton" />}</Button>}
      </Space>
      {/*Table */}
      {sites.length ? (
        <DNDTable
          pagination={isSuperAdmin() || isConsultant()}
          dataSource={sites.map((site) => ({ ...site, companyName: findCompany(site.companyId)?.title, key: site.id }))}
          columns={columns}
          rowKey={"id"}
          onUpdate={handleUpdateSorting}
          loading={loading}
        />
      ) : (
        <div style={{ background: "white", padding: 30 }}>
          <Empty
            description={
              <div>
                <Typography.Title level={4}>{<T1 pos="sites.noSite.title" />}</Typography.Title>
                <Button onClick={() => setModal("add", true)}>
                  {<T1 pos="sites.noSite.action" />} <ArrowRightOutlined />
                </Button>
              </div>
            }
          />
        </div>
      )}
      {/*Modals*/}
      <AddSiteModal key={modals.add} visible={modals.add} setVisible={(v) => setModal("add", v)} />
    </>
  );
}
