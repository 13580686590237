import { theme, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { T1 } from "../../appconfig/texts";
import {
  inputAllowed,
  inputsDoneSentence,
} from "../../pages/form/helper/getter";
import { dataForms, getForms } from "../../static/formRoutes";
import { useMediaQuery } from "react-responsive";
import { getCurrentPlan } from "../../helper/plans";

const StyledSelect = styled.div`
  .toto {
    padding: 5px 10px;
    cursor: pointer;
    min-width: ${({ isExtraSmall }) => (isExtraSmall ? "100%" : "160px")};
    max-width: 160px;
    min-height: 60px;
    max-height: 60px;
    font-size: 1em;
    border: 1px solid black;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 3px;
    box-sizing: border-box;
  }
  .selected {
    border: 4px solid forestgreen;
    box-shadow: 1px 4px 5px 0px rgba(113, 121, 178, 1);
    font-size: 1rem;
  }
  .selected::after {
    /* content: "(we can show text here)"; */
  }
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  max-width: 100%;
`;

export default function SelectDataForms({
  value,
  setValue,
  title = <T1 pos="form.q" />,
}) {
  console.log(dataForms)
  const isExtraSmall = useMediaQuery({ query: "(max-width: 600px)" });
  const isSmall = useMediaQuery({
    query: "(min-width: 600px) and (max-width: 959px)",
  });
  const isMedium = useMediaQuery({
    query: "(min-width: 960px) and (max-width: 1279px)",
  });
  const isLarge = useMediaQuery({
    query: "(min-width: 1280px) and (max-width: 1919px)",
  });
  const isExtraLarge = useMediaQuery({ query: "(min-width: 1920px)" });
  return (
    <>
      <Typography.Title level={5}>{title}</Typography.Title>
      <StyledSelect isExtraSmall={isExtraSmall}>
        {getForms().map((form) => (
          <div
            key={form.name}
            onClick={() => setValue(form.name)}
            style={{
              backgroundColor: `${theme.useToken().token.colorBgContainer}`,
            }}
            className={`toto ${inputAllowed(form) ? "" : "disabled-div"} ${
              value === form.name ? "selected" : "not-selected"
            }`}
          >
            <img src={form.bg} style={{ width: 30 }} alt={form.title} />
            <Typography.Text>
              {form.title}
              {["free", "basic", "premium"].includes(getCurrentPlan()) ? (
                <div style={{fontSize:"9px"}}>{inputsDoneSentence(form)}</div>
              ) : (
                ""
              )}
            </Typography.Text>
          </div>
        ))}
      </StyledSelect>
    </>
  );
}
