import { DeleteOutlined } from "@ant-design/icons";
import { Table, Typography } from "antd";
import { result } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../components/loading";
import {
  deleteTestResult,
  getTestResults,
} from "../../../firebase/testresult/create";
import { catcher } from "../../../firebase/util";
import { findForm } from "../../../static/formRoutes";

function generateColumns(data) {
  const uniqueKeys = data.reduce((keys, obj) => {
    Object.keys(obj).forEach((key) => {
      if (!keys.includes(key)) {
        keys.push(key);
      }
    });
    return keys;
  }, []);

  const columns = uniqueKeys.map((key) => ({
    title: key,
    dataIndex: key,
    key,
  }));

  return columns;
}

export default function TestingResults() {
  const [results, setResults] = useState(undefined);
  const [oldResults, setOldResults] = useState(undefined);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    catcher(async () => {
      const oldResults = await getTestResults();
      setOldResults(oldResults);
      setResults(
        await Promise.all(
          oldResults.map(async (oldresult) => {
            const form = findForm(oldresult.name);
            const currentResult = await form.pack(oldresult);
            return currentResult;
          })
        )
      );
      setColumns(generateColumns(oldResults));
    }, {});
  }, []);
  const handleDelete = async (id) => {
    catcher(async () => {
      await deleteTestResult(id);
      setOldResults(oldResults.filter((result) => result.id !== id));
      setResults(results.filter((result) => result.id !== id));
    }, {});
  };
  if (!results) return <Loading title="Fetching Results & Comparing" />;
  return (
    <div>
      <Typography.Title level={4}>Testing Tab Results</Typography.Title>
      <Table
        scroll={{ x: 7000, y: 1000 }}
        columns={[
          {
            render: (record, _, index) => {
              if (results[index].result === record.result) {
                return "✅";
              } else {
                return "❌";
              }
            },
            title: "Result Match",
            fixed: "left",
          },
          {
            render: (record, _, index) => {
              if (results[index].wtt === record.wtt) {
                return "✅";
              } else {
                return "❌";
              }
            },
            title: "WTT Match",
            fixed: "left",
          },
          {
            render: (record, _, index) => {
              return findForm(record.name).title;
            },
            title: "Form",
            fixed: "left",
          },
          ...columns,
          {
            render: (record, _, index) => {
              return <DeleteOutlined onClick={() => handleDelete(record.id)} />;
            },
            title: "Options",
            fixed: "right",
          },
        ]}
        dataSource={oldResults}
      ></Table>
    </div>
  );
}
