import { Typography } from "antd";
import React from "react";
import TextInput from "../../../components/input/TextInput";
import { findCompany, findSite } from "../../../helper/attribute";

export const getMetrics = (result) => {
  const inputMetrics = findCompany(findSite(result?.siteId)?.companyId).inputMetrics;
  if (Array.isArray(inputMetrics)) {
    const arr = [];
    inputMetrics.forEach((metric, index) => result.hasOwnProperty("m_" + Number(index + 1)) && arr.push("m_" + Number(index + 1)));
    return arr;
  }
};

export function formatMetrics(data) {
  const companyMetrics = findCompany(findSite(data?.siteId)?.companyId)?.inputMetrics;
  const listOfMetrics = [];
  companyMetrics?.forEach((metric, i) => {
    const key = "m_" + (i + 1);
    if (data[key]) {
      listOfMetrics.push({ title: metric.title, value: data[key] });
    }
  });
  return listOfMetrics;
}

export default function MetricsInput({ metrics, data, setData }) {
  if (!metrics) return null;
  return (
    <div>
      <Typography.Title level={5}>Metrics</Typography.Title>
      {metrics.map((metric, i) => {
        const key = "m_" + (i + 1);
        if (metric.enabled) {
          return (
            <TextInput
              numeric={metric.type === "num"}
              title={<div className="notranslate">{metric.title}</div>}
              value={data[key]}
              setValue={(e) => {
                setData({ ...data, [key]: e });
              }}
            />
          );
        }
      })}
    </div>
  );
}
