import React from "react";
import { useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, LabelList, Label, ReferenceLine } from "recharts";
import Select1 from "../../../../components/select/select1";
import { transformKeys } from "../../../../helper/wodash";
import { combineData, fixDecimalsOfObjects, GraphWrap, graph_colors } from "./config";
import { firstCompany } from "../../../../helper/attribute";
import { filterListByKeys, removePropsFromList } from "../../../../helper/obj";
import { combineEmissions } from "../../../../helper/emissionfactors";

const EmissionsScopeChart = ({ data, typeOfChart = "Years", chartOptions }) => {
  let [type, setType] = useState("Scope");

  let keys = ["Scope 1", "Scope 2", "Scope 3"];
  if (type === "Emissions Source") {
    keys = [];
    data = combineEmissions(filterListByKeys(data, ["1", "2", "3"]), 0.05, true);
    data.forEach((row) => {
      const keystemp = Object.keys(row);
      keys = [...keys, ...keystemp];
    });
    keys = [...new Set(keys)];
  }
  data = fixDecimalsOfObjects(data);
  data = transformKeys(data, (key) => {
    if (!isNaN(key)) {
      return "Scope " + key;
    }
    return key;
  });

  let target;
  if (chartOptions.xLabel === "Years") {
    target = firstCompany()?.annualTarget;
  } else if (chartOptions.xLabel === "Months") {
    target = firstCompany()?.monthlyTarget;
  }
  return (
    <div style={{ zIndex: 1000 }}>
      <div className="type-graph-analysis">
        <Select1 title="Type" value={type} options={["Scope", "Emissions Source"]} setValue={setType}></Select1>
      </div>
      <GraphWrap {...chartOptions} showLabels>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 10" />
          <XAxis dataKey="col" padding={100} fontSize={12}></XAxis>
          <YAxis></YAxis>
          <Legend wrapperStyle={{ margin: "40px 0px 0px 30px" }} />
          <Tooltip />
          {/**USE ONLY REQUIRED KEYS SCOPE ONES OR EMISSION ONES */}
          {keys.map((scope, index) => {
            if (scope === "col") return null;
            return (
              <Bar dataKey={scope} stackId="a" fill={getColorForBar(index)}>
                {data.map((entry, index2) => {
                  return <Cell key={`cell-${index2}`} />;
                })}
                {/* <LabelList dataKey="key" position="top" fill="green" /> */}
              </Bar>
            );
          })}
          {target && (
            <ReferenceLine
              y={target}
              stroke="red"
              ifOverflow="extendDomain"
              label={{
                value: "🎯 Target",
                position: "insideTopRight", // Label position
                fill: "brown", // Label color
                style: { fontSize: 12, fontWeight: "bold", right: -10, top: 5 },
              }}
            />
          )}
        </BarChart>
      </GraphWrap>
    </div>
  );
};

const getColorForBar = (index) => {
  return graph_colors[index % graph_colors.length];
};
export default EmissionsScopeChart;
