import { useSelector } from "react-redux";
import { T1 } from "../appconfig/texts";
import { selectAffiliate } from "../redux/features/appSlice";
import { getCurrentPlan } from "../helper/plans";

export const Base64Image = ({ base64, alt }) => {
  // Ensure the base64 string is prefixed correctly for an image source
  const src = `data:image/jpeg;base64,${base64}`;

  return (
    <img
      src={src}
      alt={alt}
      className="heading-logo"
      style={{ maxWidth: "100px", maxHeight: "100px", marginTop: "0px" }}
    />
  );
};

export function Logo({ className }) {
  const af = useSelector(selectAffiliate)?.c;
  if (af) return <Base64Image base64={af.logo} alt={af.app_name} />;

  return (
    <img
      className={className}
      src={require("../static/logos/carbonfootprintlogo.gif")}
    ></img>
  );
}
export function AppTitle() {
  const af = useSelector(selectAffiliate);
  if (!af)
    return (
      <div className="notranslate">
        Sustrax <T1 pos={`plans.${getCurrentPlan()}.title`} />
      </div>
    );
  else return af.title;
}

export function LoginContent() {
  const af = useSelector(selectAffiliate);
  if (!af) {
    return (
      <>
        <h1>Welcome to Sustrax</h1>
        <p>
          Sustrax enables you to measure, manage and report your organisation’s
          environmental data and carbon emissions from anywhere in the world.
        </p>
      </>
    );
  } else {
    return (
      <>
        <h1>Welcome to {af.title}</h1>
        <p>
          {af.title} enables you to measure, manage and report your organisation’s
          environmental data and carbon emissions from anywhere in the world.
        </p>
      </>
    );
  }
}
