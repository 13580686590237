import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import Select1 from "../../components/select/select1";
import TextInput from "../../components/input/TextInput";
import * as XLSX from "xlsx";
import { reverseObj } from "../../helper/wodash";
import { findFormTexts } from "../../helper/attribute";

const CONFIG_SHEET_NAMES = [
  "wexel____site_data",
  "wexel____dropdowns_____wexel",
  "Config"
];

export default function Settings({ settings, setSettings, data }) {
  const [desc, setDesc] = useState(false);
  const formFields = {};
  findFormTexts(data.name, "forms").forEach(
    (obj) => (formFields[obj.name] = obj.title)
  );
  useEffect(() => {
    const InitProcedure = () => {
      const reader = new FileReader();
      const file = data.file;
      reader.readAsArrayBuffer(file);
      let rows = [],
        errorRows = [];
      reader.onload = async (event) => {
        rows = event.target.result;
        console.log(rows);
        const workbook = XLSX.read(rows, { type: "array" });

        const sheetNames = workbook.SheetNames.filter(
          (n) => !CONFIG_SHEET_NAMES.includes(n)
        );
        if (!sheetNames.includes("Inputdata")) {
          // automatically set the default sheet name
          settings.sheetName = sheetNames[0];
        } else {
          settings.sheetName = "Inputdata";
        }
        // checking if the sheet type is monthly by checking year in the first column

        const sheet = workbook.Sheets[settings.sheetName];

        rows = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          defval: null,
          blankrows: false,
        });
        if (rows[0]?.includes("Year")) {
          settings.type = "Monthly";
        } else {
          settings.type = "Default";
        }
        setSettings({ ...settings });
      };
    };
    InitProcedure();
  }, [data.file, data]);
  return (
    <div style={{ width: "fit-content" }}>
      <Typography.Title level={3}>Configuration</Typography.Title>
      <div style={{ padding: 10 }}>
        {/* <Select1
          value={settings.type}
          setValue={(type) => setSettings({ ...settings, type })}
          title="Type of template"
          options={["Monthly", "Default"]}
          description={
            settings.type === "Monthly"
              ? "Looks like a monthly template has been used!"
              : "Default template selected"
          }
        /> */}
      </div>
      <TextInput
        bgless
        borderless
        description={`You can change the name of the data sheet.`}
        title="Name of Data sheet"
        value={settings.sheetName}
        setValue={(sheetName) => setSettings({ ...settings, sheetName })}
      />
    </div>
  );
}
