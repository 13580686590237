// Importing necessary components and icons
import { CrownOutlined, DeleteOutlined, FileOutlined, HomeOutlined, LockOutlined, SettingOutlined, UnlockOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Divider, Modal, Select, Space, Tabs, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import texts, { T1 } from "../../../appconfig/texts";
import currencies from "../../../assets/currencies.json";

// Importing custom components and helper functions
import FourOhFour from "../../../components/404";
import NumberInput from "../../../components/input/number";
import FakeLabel from "../../../components/input/select";
import TextInput from "../../../components/input/TextInput";
import CountrySelect from "../../../components/select/country";
import FormContext from "../../../contexts/FormContext";
import { closeSite, openSite } from "../../../firebase/site/close";
import { deleteSite } from "../../../firebase/site/delete";
import { fetchSites } from "../../../firebase/site/get";
import { updateSite } from "../../../firebase/site/update";
import { except } from "../../../firebase/util";
import { designations, findCompany, findSite, findSitesByCompanyId, getCurrentUserDesignation } from "../../../helper/attribute";
import { selectTheme } from "../../../redux/features/appSlice";
import SiteAdmins from "./admins";
import FileSelectSite from "./DetailsPages/file";
import SelectSearch from "../../../components/select/SearchSelect";
import { getGHGs, getGHGTitle, GHG_CATEGORIES_VALUES } from "../../../static/ghg.static";
import Select1 from "../../../components/select/select1";
import SimpleSelect from "../../../components/select/simpleselect";
import { removeDuplicates } from "../../../helper/wodash";

// SiteProfile component
const SiteProfile = ({ siteData }) => {
  const [oldData, setOldData] = useState({ ...siteData });
  const [data, setData] = useState({ ...siteData });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUpdate = () => {
    except(async () => {
      setLoading(true);
      await updateSite(siteData.id, data);
      // Setting local state
      setOldData(data);
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setData({ ...oldData });
  };

  const isUpdated = JSON.stringify(data) !== JSON.stringify(oldData);

  console.log(data);

  const getAllTags = () => {
    let tags = [];
    const sites = findSitesByCompanyId(data.companyId, true);
    sites.forEach((site) => {
      if (site.tags) tags = [...tags, ...site.tags];
    });
    tags = removeDuplicates(tags);
    return tags.map((tag) => ({ label: tag, value: tag }));
  };

  return (
    <div>
      <FormContext.Provider
        value={{
          fixedWidth: "500px",
          bgless: true,
          borderless: true,
          minWidthLabel: "200px",
          minWidthElem: "540px",
          maxWidthElem: "500px",
        }}
      >
        <Card>
          <Space direction="vertical">
            <Typography.Title level={3}>{<T1 pos="site.profile.text" />}</Typography.Title>
            <TextInput displayOnly value={data.createdAt + "🗓️"} title={"Added on"} />

            <TextInput value={data.title} setValue={(e) => setData({ ...data, title: e })} title={<T1 pos="site.profile.name" />} />
            <CountrySelect title={<T1 pos="site.profile.country" />} value={data.country} setValue={(country) => setData({ ...data, country })} />
            <FakeLabel
              title={<T1 pos="site.profile.tags" />}
              elem={
                <Select
                  notFoundContent={<>Enter tags seperated by commas</>}
                  placeholder={texts.site.profile.tagsPlaceHolder}
                  mode="tags"
                  value={data.tags}
                  onChange={(tags) => setData({ ...data, tags })}
                  style={{
                    width: "400px",
                  }}
                  tokenSeparators={[","]}
                  options={getAllTags()}
                  rootClassName="notranslate"
                />
              }
            ></FakeLabel>
            <NumberInput
              title={<T1 pos="site.profile.factors" />}
              value={data.supplier_factors}
              setValue={(supplier_factors) => setData({ ...data, supplier_factors })}
              description={<T1 pos="site.profile.factorsInfo" />}
            />
            <SelectSearch
              title={"Currency"}
              description={"Use this to set currency."}
              options={currencies.map((curr) => curr.name)}
              value={data.curr}
              setValue={(curr) => setData({ ...data, curr })}
            />
            <SimpleSelect
              title={"Overwrite GHG category"}
              description={
                "This will move all Scope 1 and 2 elements except company cars for this site into this category. It is recommended this is discussed with your account manager before changing."
              }
              allowDefault={true}
              options={[6, 13, 16, 18].map((ghg) => ({ label: getGHGTitle(ghg), value: ghg }))}
              value={data._overwrite_ghg ?? null}
              setValue={(value) => {
                setData({ ...data, _overwrite_ghg: value });
              }}
            ></SimpleSelect>
            <Space>
              <Button loading={loading} onClick={handleUpdate} type="primary" disabled={!isUpdated}>
                Update
              </Button>
              {isUpdated && <Button onClick={handleCancel}>Cancel</Button>}
            </Space>
          </Space>
        </Card>
      </FormContext.Provider>
    </div>
  );
};

// Admins component
const Admins = ({ siteData }) => {
  return (
    <Card>
      <SiteAdmins _={siteData} />
    </Card>
  );
};

// Settings component
const Settings = ({ siteData }) => {
  const navigate = useNavigate();

  const handleDeleteSite = () => {
    Modal.confirm({
      title: <T1 pos="site.settings.deleteSite" />,
      content: <T1 pos="site.settings.deleteSiteConfirm" />,
      onOk: () => {
        except(async () => {
          await deleteSite(siteData);
          await fetchSites();
          navigate("/dashboard");
        });
      },
    });
  };

  const handleCloseSite = () => {
    Modal.confirm({
      title: <T1 pos="site.settings.closeSite" />,
      content: <T1 pos="site.settings.closeSiteConfirm" />,
      onOk: () => {
        except(async () => {
          await closeSite(siteData.id);
          await fetchSites();
          // navigate("/dashboard");
        });
      },
    });
  };

  const handleOpenSite = () => {
    Modal.confirm({
      title: <T1 pos="site.settings.reOpenSite" />,
      content: <T1 pos="site.settings.reOpenSiteConfirm" />,
      onOk: () => {
        except(async () => {
          await openSite(siteData.id);
          await fetchSites();
          // navigate("/dashboard");
        });
      },
    });
  };

  return (
    <Card>
      <Typography.Title level={3}>{<T1 pos="site.settings.text" />}</Typography.Title>
      <Space>
        {!siteData.closed ? (
          <Button
            onClick={handleCloseSite}
            style={{
              background: "darkgrey",
              color: "white",
            }}
          >
            {<T1 pos="site.settings.closeSite" />}
            <LockOutlined />
          </Button>
        ) : (
          <Button onClick={handleOpenSite} style={{ background: "forestgreen", color: "white" }}>
            {<T1 pos="site.settings.reOpenSite" />}
            <UnlockOutlined />
          </Button>
        )}
        <Button onClick={handleDeleteSite} style={{ background: "darkred", color: "white" }}>
          {<T1 pos="site.settings.deleteSite" />}
          <DeleteOutlined />
        </Button>
      </Space>
    </Card>
  );
};

// SiteDetails component
export default function SiteDetails() {
  const { id } = useParams();
  const site = findSite(id);
  const theme = useSelector(selectTheme);

  const items = [
    {
      key: "1",
      label: (
        <>
          <HomeOutlined />
          {<T1 pos="site.profile.text" />}
        </>
      ),
      children: <SiteProfile siteData={site} />,
    },
    {
      key: "2",
      label: (
        <>
          <CrownOutlined /> {<T1 pos="site.siteAdmins.text" />}
        </>
      ),
      children: <Admins siteData={site} />,
    },
    {
      key: "3",
      label: (
        <>
          <FileOutlined /> {<T1 pos="site.files.text" />}
        </>
      ),
      children: <FileSelectSite id={id} />,
    },
    {
      key: "4",
      label: (
        <>
          <SettingOutlined /> {<T1 pos="site.settings.text" />}
        </>
      ),
      children: <Settings siteData={site} />,
    },
  ];

  // Check if the user has the appropriate designation to access this page
  if (![designations.company_admin, designations.group_admin, designations.super_admin, designations.site_admin, designations.affiliate].includes(getCurrentUserDesignation())) {
    return <FourOhFour />;
  }
  const isSiteClosed = site.closed;
  return (
    <>
      {isSiteClosed && (
        <Alert
          style={{ padding: 10, marginBottom: 20 }}
          type="info"
          showIcon
          message={<T1 pos="site.settings.siteClosed" />}
          description={<T1 pos={"site.settings.siteClosedMessage"} replace={{ lastClosed: site?.lastClosed }} />}
        ></Alert>
      )}
      <Tabs className={`vertical-tabs ${!theme.isDarkMode ? "bg-white" : "bg-black"}`} defaultActiveKey="1" tabPosition="left" items={items} />
    </>
  );
}
