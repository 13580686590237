// Recalculate Results Modal

import { Modal, Typography } from "antd";
import { doc, writeBatch } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ResultTable } from ".";
import { db } from "../../firebase/setup";
import { catcher } from "../../firebase/util";
import { setResults } from "../../redux/features/appSlice";
import store from "../../redux/store";
import { firestoreStatic } from "../../static/firestore.static";
import { dataForms, findForm } from "../../static/formRoutes";

export default function RecalculateModal({ open, setOpen, dataRows = [] }) {
  const [overwriteResults, setOverwriteResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const form = findForm(dataRows[0]?.name);
      const newResults = await Promise.all(dataRows.map(async (row) => {
        const newResult = await form.pack(JSON.parse(JSON.stringify(row)));
        return newResult;
      }));
      setOverwriteResults(newResults);
    };

    fetchData();
  }, []);
  const handleUpdateRows = async () => {
    await catcher(
      async () => {
        const batch = writeBatch(db);
        overwriteResults.forEach((result) => {
          const ref = doc(db, firestoreStatic.results, result?.id);
          batch.update(ref, result);
        });
        await batch.commit();
        dispatch(
          setResults(
            store.getState().app.results.map((result) => {
              const updatedResult = overwriteResults.find(
                (prevResultRow) => prevResultRow.id === result.id
              );
              return updatedResult || result;
            })
          )
        );
      },
      { setLoading }
    );
    setOpen(false);
  };
  return (
    <>
      <Modal
        width={"1400px"}
        onCancel={() => setOpen(false)}
        open={open}
        onOk={handleUpdateRows}
        okText="Update"
        cancelText={"Go Back"}
        okButtonProps={{ loading }}
      >
        <Typography.Title>Recalculated data</Typography.Title>
        <ResultTable
          name={dataRows[0]?.name}
          renderTableOnly={true}
          overwriteResults={overwriteResults}
        />
      </Modal>
    </>
  );
}
