import { NumberOutlined } from "@ant-design/icons";
import { TbHexagonLetterG } from "react-icons/tb";
import { LuArrowUpDown, LuDivideCircle } from "react-icons/lu";
import { Tooltip } from "antd";

const PROPS = { style: { fontSize: "20px" } };

const icons = {
  spreadTag: <span style={{ fontSize: "20px" }}>Sp</span>,
  scaleTag: <span style={{ fontSize: "20px" }}>Sc</span>,
  ghgTag: <TbHexagonLetterG {...PROPS} />,
  supplier_factor: <NumberOutlined {...PROPS} />,
};

export default icons;
