import { Input } from "antd";
import moment from "moment";
import EllipsisText from "../components/text/EllipsisText";
import { timeFormat } from "../helper/time";
import useSize from "../hooks/useSize";
import formatEmissions from "../components/text/resultEmissions";
import { getResultDates } from "../pages/results/resultsCalcs";
import { firstCompany } from "../helper/attribute";
import { useState } from "react";
import { SitePageLink } from "../components/Link/Link";

function removeObjectsByValue(array, property, valuesToRemove) {
  return array.filter((obj) => !valuesToRemove.includes(obj[property]));
}

function removeDuplicateObjects(arr) {
  return arr.filter(
    (obj, index, self) =>
      index ===
      self.findIndex((item) => JSON.stringify(item) === JSON.stringify(obj))
  );
}

export const antdtablefilters = {
  select: (dataSource, key) => ({
    filters: removeObjectsByValue(
      removeDuplicateObjects(
        dataSource.map((s) => ({ text: s[key], value: s[key] }))
      ),
      "text",
      ["", undefined, null]
    ),
    onFilter: (val, record) => {
      return val === record[key];
    },
  }),
  sort: (key = "date") => ({
    sorter: (a, b) =>
      key.includes("date")
        ? moment(a["date"], timeFormat).toDate().getTime() -
          moment(b["date"], timeFormat).toDate().getTime()
        : parseFloat(String(a[key]).replaceAll(",", "")) -
          parseFloat(String(b[key]).replaceAll(",", "")),
  }),
  input: (key) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search name"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <button
          type="button"
          onClick={() => confirm()}
          style={{ marginRight: 8 }}
        >
          Filter
        </button>
        <button type="button" onClick={() => setSelectedKeys([])}>
          Reset
        </button>
      </div>
    ),
    // Filtering logic
    onFilter: (value, record) =>
      record[key].toLowerCase().includes(value.toLowerCase()),
  }),
};

const Combined_table_obj = (dataSource) => {
  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  const [companyMetrics, setCompanyMetrics] = useState(
    firstCompany()?.inputMetrics?.filter((metric) => metric.enabled) ?? []
  );
  if (!dataSource) return {};
  return [
    { title: "No.", key: "index", width: 70 },
    {
      title: "Type",
      key: "name",
      ...antdtablefilters.select(dataSource, "name"),
      width: 120,
    },
    {
      title: "Company",
      key: "companyId",
      className:"notranslate",
      ...antdtablefilters.select(dataSource, "companyId"),
      width: 120,
    },
    {
      title: "Site",
      key: "siteId",
      ...antdtablefilters.select(dataSource, "siteId"),
      className:"notranslate",
      width: 120,
    },
    {
      title: "Date entered",
      key: "date",
      ...antdtablefilters.sort(),
      width: 130,
    },
    { title: "Details 1", key: "details1", width: 200 },
    { title: "Details 2", key: "details2", width: 200 },
    {
      title: "Amount",
      key: "amount",
      ...antdtablefilters.sort("amount"),
      width: 100,
    },
    { title: "UOM", key: "uom", width: 100 },
    {
      title: "Amount 2",
      key: "amount2",
      ...antdtablefilters.sort("amount2"),
      width: 100,
    },
    { title: "UOM 2", key: "uom2", width: 100 },
    {
      title: "GHG Category",
      key: "ghg",
      render: function (val) {
        return <EllipsisText text={val} maxLength={50} />;
      },
      ...antdtablefilters.select(dataSource, "ghg"),
      width: 120,
    },
    {
      title: "WTT Category",
      key: "ghgwtt",
      render: function (val) {
        return <EllipsisText text={val} maxLength={50} />;
      },
      ...antdtablefilters.select(dataSource, "ghgwtt"),
      width: 120,
    },
    {
      title: "SECR kWh",
      key: "kwh",
      ...antdtablefilters.sort("kwh"),
      width: 100,
    },
    {
      title: "Comment",
      key: "comment",
      // render: function (val) {
      //   return <EllipsisText text={val} maxLength={50} />;
      // },
      width: 150,

      // ...antdtablefilters.input("comment"),
    },
    {
      title: "Data Tag",
      key: "resulttag",
      // render: function (val) {
      //   return val;
      // },
      width: 120,
      ...antdtablefilters.select(dataSource, "resulttag"),

      // ...antdtablefilters.input("comment"),
    },
    {
      title: "Data Link",
      key: "dataLink",
      // render: function (val) {
      //   return <EllipsisText text={val} link maxLength={20}></EllipsisText>;
      // },
      width: 120,

      // ...antdtablefilters.input("comment"),
    },
    {
      title: "Added by (Name)",
      key: "userName",
      width: 150,
      className:"notranslate",

    },
    {
      title: "Added by (Email)",
      key: "userEmail",
      
      width: 150,
      className:"notranslate",
    },
    {
      title: "Calendar Month",
      key: "calendarMonth",
      width: 100,
      ...antdtablefilters.select(dataSource, "calendarMonth"),

      // render: (_, result) => getResultDates(result).calendarMonth,
    },
    {
      title: "Fiscal Year",
      key: "fiscalYear",
      width: 100,
      ...antdtablefilters.select(dataSource, "fiscalYear"),
      // render: (_, result) => getResultDates(result).fiscalYear,
    },
    {
      title: "Fiscal Month",
      key: "fiscalMonth",
      width: 100,
      ...antdtablefilters.select(dataSource, "fiscalMonth"),
      // render: (_, result) => getResultDates(result).fiscalMonth,
    },
    ...companyMetrics?.map((metric, index) => {
      const dataIndex = "m_" + (index + 1);
      return {
        title: metric.title,
        dataIndex,
        key: dataIndex,
        width: 140,
      };
    }),
    {
      title: "Emissions (tCO₂e)",
      key: "result",
      fixed: isExtraSmall ? "" : "right",
      ...antdtablefilters.sort("result"),
      width: 120,
      render: (val) => formatEmissions(val, true),
    },
    {
      title: "WTT Emissions (tCO₂e)",
      key: "wtt",
      fixed: isExtraSmall ? "" : "right",
      ...antdtablefilters.sort("wtt"),
      width: 120,
      render: (val) => formatEmissions(val, true),
    },
    {
      title: "Overall Total (tCO₂e)",
      key: "overall_total",
      fixed: isExtraSmall ? "" : "right",
      ...antdtablefilters.sort("result"), // todo: make a seperate sorter
      width: 120,
      render: (val) => formatEmissions(val, true),
    },

    { title: "Options", key: "options", width: 100, fixed: "right" },

    // { title: "ID(testing)", key: "id" },
  ];
};

export function mergeArrays(arrays) {
  const maxLength = Math.max(...arrays.map((arr) => arr.length));
  const mergedArray = [];

  for (let i = 0; i < maxLength; i++) {
    for (const arr of arrays) {
      if (i < arr.length) {
        mergedArray.push(arr[i]);
      }
    }
  }

  return mergedArray;
}

export default Combined_table_obj;
