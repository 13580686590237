import React, { useEffect, useState } from "react";
import { Card, Row, Col, Typography, Spin } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import Loading from "../../../components/loading";
import { getAppInfo } from "../../../firebase/appinfo/create";
import { catcher } from "../../../firebase/util";
import {
  addOrReplaceQueryParam,
  getAllParamsFromLocation,
} from "../../../helper/loc";
import { getFormName } from "../testing/config";
import { getCurrentPlan } from "../../../helper/plans";

const Learn = () => {
  const [videoData, setVideoData] = useState({});
  const [currentPlan] = useState(getCurrentPlan);
  const [loading, setLoading] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(
    getAllParamsFromLocation()?.id
  );

  useEffect(() => {
    catcher(
      async () => {
        let data_full = await getAppInfo("form_vid_links");
        let data = {};
        Object.keys(data_full).forEach((key) => {
          if (
            key.includes("-" + currentPlan) &&
            data_full[key] &&
            data_full[key] !== ""
          ) {
            data[key] = data_full[key];
            console.log(key, "=", data[key] ?? "value not present");
          }
        });
        const convertedData = convertVideoLinks(data);
        setVideoData(convertedData);
      },
      { success_msg: "", setLoading }
    );
  }, []);

  if (!videoData) return <Loading />;

  const handleSetCurrentVideo = (formName) => {
    addOrReplaceQueryParam("id", formName);
    setCurrentVideo(formName);
    window.scroll({ top: 0 });
  };

  // return <Loading title="Page in progress, you will see it soon."/>

  return (
    <div style={{ padding: "30px" }}>
      {currentVideo && (
        <>
          <Typography.Title
            level={3}
            style={{ marginTop: 0, lineHeight: 0, marginBottom: 50 }}
          >
            {getFormName(currentVideo)} input tutorial
          </Typography.Title>
          <Spin spinning={loading}>
            <iframe
              title={currentVideo}
              width="100%"
              height="700px"
              src={videoData[currentVideo]}
              frameborder="0"
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style={{
                marginBottom: "20px",
                border: "1px solid black",
                borderRadius: "10px",
              }}
            />
          </Spin>
        </>
      )}
      <Typography.Title level={3}>All tutorials</Typography.Title>
      <Row gutter={[16, 16]}>
        {Object.entries(videoData).map(([key, embedUrl]) => {
          console.log(getFormName(key));
          if (getFormName(key) && !getFormName(key)?.includes("undefined"))
            return (
              <Col xs={24} sm={12} md={6} lg={6} xl={6} key={key}>
                <Card
                  hoverable
                  style={{ position: "relative" }}
                  cover={
                    <>
                      <img
                        alt={key}
                        src={`https://img.youtube.com/vi/${extractYoutubeId(
                          embedUrl
                        )}/0.jpg`}
                        style={{ width: "100%" }}
                      />
                      <PlayCircleOutlined
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -100%)",
                          fontSize: "100px",
                          color: "white",
                        }}
                      />
                    </>
                  }
                  onClick={() => handleSetCurrentVideo(key)}
                >
                  <Card.Meta
                    title={`${getFormName(key)} tutorial`}
                    description="Click to watch the video"
                  />
                </Card>
              </Col>
            );
        })}
      </Row>
    </div>
  );
};

function convertVideoLinks(data) {
  const convertedData = {};
  for (const key in data) {
    const videoId = extractYoutubeId(data[key]);
    convertedData[
      key
    ] = `https://www.youtube.com/embed/${videoId}?autoplay=1&allowfullscreen=1&playlist=${videoId}&loop=1&rel=0`;
  }
  return convertedData;
}

function extractYoutubeId(url) {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}

export default Learn;
