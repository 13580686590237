export function randomFormData(name = "add") {
    if (name === "additional") {
        return {
            date: "15/11/2023",
            ghg: 6,
            ghgwtt: 6,
            q1: "Yes",
            result: 22,
            wtt: 22
        };
    }
}