import axios from "axios";
import { useSelector } from "react-redux";
import { selectUserDoc, setUserDoc } from "../../redux/features/userSlice";
import { updateUser } from "../../firebase/user/update";
import store from "../../redux/store";
import formatMessage, { updateThreadID } from "./chatbothelper";

const API_URL = `https://saboorislam.pythonanywhere.com/run/auto`;

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  getItemFromLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  };

  handleMessage = async (message) => {
    console.log(message)
    if (message.trim() === "") {
      const errorMessage = this.createChatBotMessage("The text field is empty. Please enter a message.");
      this.setChatbotMessage(errorMessage);
      return;
    }

    const currentUser = store.getState().user.doc;

    // Set waiting message
    const waitingMessage = this.createChatBotMessage("Please wait...");
    this.setChatbotMessage(waitingMessage);


    // Call the API to get a response
    try {
      const response = await axios.post(
        API_URL,
        {"threadID":currentUser?.["bot_thread_id"]? currentUser?.["bot_thread_id"] : null,
          "message":message
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      data.resp_text = formatMessage(data.resp_text)


      if(data.threadID != null){
        updateThreadID(currentUser,data.threadID)
        console.log("New Thread Created and stored")
      }

      

      

      const botMessage = this.createChatBotMessage(data.resp_text);
      this.updateChatbotMessage(waitingMessage.id, botMessage);
    } catch (err) {
      console.log(err);
      const botMessage = this.createChatBotMessage(`Error: ` + err);
      this.updateChatbotMessage(waitingMessage.id, botMessage);
    }
  };

  setChatbotMessage = (message) => {
    console.log(message);
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));

    // Save bot's message to local storage
    this.saveMessageToLocalStorage("response", message.message);
  };

  updateChatbotMessage = (id, newMessage) => {
    this.setState((prev) => {
      const updatedMessages = prev.messages.map((msg) => {
        if (msg.id === id) {
          return newMessage;
        }
        return msg;
      });
      return { ...prev, messages: updatedMessages };
    });

    // Update bot's message in local storage
    const savedMessages = JSON.parse(localStorage.getItem("chatMessages")) || [];
    const updatedMessages = savedMessages.map((msg) => {
      if (msg.text === "Please wait...") {
        return { type: "response", text: newMessage.message };
      }
      return msg;
    });
    localStorage.setItem("chatMessages", JSON.stringify(updatedMessages));
  };

  saveMessageToLocalStorage = (type, text) => {
    const savedMessages =
      JSON.parse(localStorage.getItem("chatMessages")) || [];
    savedMessages.push({ type, text });

    // Ensure only the last 100 messages are stored
    if (savedMessages.length > 100) {
      savedMessages.splice(0, savedMessages.length - 100);
    }

    localStorage.setItem("chatMessages", JSON.stringify(savedMessages));
  };
}

export default ActionProvider;
