import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect } from "react";
import AntDrop from "../../../components/input/antddropdown";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { findForm } from "../../../static/formRoutes";
import { genPut } from "../helper/create";

export const ExcelExport = (props) => {
  const handleOnChange = (name) => {
    const q1 = name === "Fuel" ? "Yes" : "No";
    props.downloadTemplate({ ...props.form, q1 }, { q1 });
  };
  return (
    <AntDrop options={["Fuel", "Distance"]} onChange={handleOnChange}>
      <Button type="link">
        Download Template <DownloadOutlined />
      </Button>
    </AntDrop>
  );
};

export const INPUTS = (data, setData, formname) => [
  {
    key: "form",
    elem: SelectSearch,
    options: findForm(data.name).formTypes,
  },
  {
    key: "date",
    elem: DateInput,
  },
  {
    key: "q1",
    elem: SelectSearch,
    options: ["Yes", "No"],
    set: { uom: null, columntext: null, type: null },
    excelShow: false,
  },
  {
    key: "type",
    elem: SelectSearch,
    options:
      data.q1 === "No"
        ? filterFactors({ form: "cars" }, "Level 3")
        : ["Diesel (average biofuel blend)", "Petrol (average biofuel blend)"],
    set: { uom: null, columntext: null },
  },
  {
    key: "columntext",
    elem: SelectSearch,
    options: filterFactors(
      { form: "cars", "Level 3": data.type },
      "Column Text"
    ),
    set: { uom: null },
    excelShow: data.q1 === "No",
  },
  {
    key: "uom",
    elem: SelectSearch,
    options:
      data.q1 === "No"
        ? filterFactors(
            {
              form: "cars",
              "Level 3": data.type,
              "Column Text": data.columntext,
            },
            "UOM"
          )
        : ["litres", "tonnes"],
  },
  {
    key: "distance",
    elem: NumberInput,
    hidden: data.q1 === "Yes",
    excelShow: data.q1 === "No",
  },
  {
    key: "amount",
    elem: NumberInput,
    hidden: data.q1 === "No",
    excelShow: data.q1 === "Yes",
  },
];

export default function Cars({ data, setData }) {
  const formname = data.name;

  useEffect(() => {
    setData({
      ...data,
      q1: data.q1 ? data.q1 : "Yes",
      form: data.form ? data.form : findForm(data.name).formTypes[0],
    });
  }, []);

  return (
    <>
      {INPUTS(data, setData, formname).map((input) => {
        return genPut(input, formname, data, setData);
      })}
    </>
  );
}
