import { ConfigProvider, theme } from "antd";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import checksBeforeLogin from "./Checks/BeforeLogin";
import ErrorBoundary from "./ErrorBoundary";
import Login from "./auth/login";
import AllApp from "./components/AllApp/AllApp";
import Loading from "./components/loading";
import { fetchAppConfig } from "./firebase/appconfig/get";
import { fetchCompanies } from "./firebase/company/get";
import { fetchResults } from "./firebase/data/get";
import { fetchGroups } from "./firebase/group/get";
import { fetchSites } from "./firebase/site/get";
import { fetchEmissionFactors } from "./firebase/storage/assets";
import { fetchUsers, getUserDoc } from "./firebase/user/get";
import { fetchReportBugs } from "./firebase/userhelp/reportbugs/get";
import { designations, getCurrentUserDesignation, isConsultant, isSuperAdmin } from "./helper/attribute";
import { selectCompanies, selectSites, selectTheme, selectUsers, setCompanies, setGroups, setResults, setSites, setUsers } from "./redux/features/appSlice";
import { selectUserDoc, setUserDoc } from "./redux/features/userSlice";
import routes from "./routes";
import "./styles/animations.css";
import "./styles/antd.css";
import store from "./redux/store";
import { checksAfterLogin } from "./Checks/AfterLogin";
import affiliateChecks from "./affiliates/checkAfterLogin";
import { catcher } from "./firebase/util";

const App = () => {
  const content = useRoutes(routes());
  const userDoc = useSelector(selectUserDoc);
  const users = useSelector(selectUsers);
  const companies = useSelector(selectCompanies);
  const sites = useSelector(selectSites);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useSelector(selectTheme);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Before Login
    checksBeforeLogin();
  }, []);
  useEffect(() => {
    catcher(
      async () => {
        await affiliateChecks();
      },
      { setLoading, success_msg: "" }
    );
  }, []);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      dispatch(setUserDoc(undefined));
      console.timeEnd("Loggin Time");
      try {
        if (user === null) throw "Relogin Please";
        const token = await getIdToken(user);
        const userDoc = await getUserDoc();

        dispatch(setUserDoc({ ...userDoc, token }));
        await fetchData();
        if (getCurrentUserDesignation() === designations.super_admin) {
          fetchSuperAdminData();
        }
      } catch (err) {
        dispatch(setUserDoc(null));
        navigate("/login");
      }
    });
  }, []);
  const fetchData = async (filterCompanies = false) => {
    await fetchAppConfig();
    if (isSuperAdmin() && window.confirm("Fetch companies and sites along with admin config?") === false) {
      store.dispatch(setCompanies([]));
      store.dispatch(setSites([]));
      store.dispatch(setUsers([]));
      store.dispatch(setGroups([]));
    } else {
      await fetchGroups();
      await fetchCompanies(filterCompanies);
      await fetchSites();
      await fetchUsers();
    }
    await checksAfterLogin();

    if (location.pathname === "/" || location.pathname === "") navigate("/dashboard");
    fetchEmissionFactors();
    if (isSuperAdmin() || isConsultant()) {
      store.dispatch(setResults([]));
    } else {
      fetchResults();
    }
  };

  const fetchSuperAdminData = () => {
    fetchReportBugs();
  };
  if (loading) return <Loading title="Loading Sustrax MX" />;
  if (userDoc?.requireTwofa && userDoc?.authCode) {
    return <Login type={"auth"} />;
  }
  if (userDoc === null) return <Login />;
  if (users === undefined) {
    return <Loading title="Loading Users" />;
  } else if (companies === undefined) {
    return <Loading title="Loading Companies" />;
  } else if (sites === undefined) {
    return <Loading title="Loading Sites" />;
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "forestgreen",
        },
        algorithm: !isDarkMode ? theme.defaultAlgorithm : theme.darkAlgorithm,
      }}
    >
      <ErrorBoundary>
        <div
          style={{
            minHeight: "100vh",
          }}
        >
          {content}
          <AllApp refetchData={fetchData} />
        </div>
      </ErrorBoundary>
    </ConfigProvider>
  );
};

export default App;
