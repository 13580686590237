import React from "react";

import { Tabs } from "antd";
import ErrorBoundary from "../../../../ErrorBoundary";
import { T1 } from "../../../../appconfig/texts";
import { planValue } from "../../../../helper/plans";
import EmissionsBarChart from "../charts/EmissionsBarChart";
import EmissionsPieChart from "../charts/EmissionsPieChart";
import EmissionsScopeChart from "../charts/EmissionsScopeBarChart";
import EmissionsScopePieChart from "../charts/EmissionsScopePieChart";
import Boxed from "./boxed";
import DownloadDomImage from "../../../../components/canvas/downloadDomImage";
import { getFormName } from "../config";
import { firstCompany } from "../../../../helper/attribute";

export default function Graph({
  dataSource,
  hidden = [],
  scopePieChartElem,
  scopePieChartFilters,
  typeOfChart,
  chartOptions = {},
  showDownloadAsPNG,
}) {
  return (
    <div className="graph">
      {
        <ErrorBoundary>
          <div id={JSON.stringify(dataSource)} style={{ position: "relative" }}>
            <Boxed title={<T1 pos={"analysis.graph.title"} />}>
              {/* <BeautifyData data={dataSource} /> */}
              {showDownloadAsPNG && (
                <DownloadDomImage
                  fileName={`Chart Export ${firstCompany()?.title}.png`}
                  makeAbsolute
                  elementId={JSON.stringify(dataSource)}
                  text={
                    "Graph created by Sustrax MX from carbonfootprint.com"
                  }
                />
              )}
              <Tabs
                className="tabs-graph-analysis"
                style={{ padding: 10 }}
                defaultActiveKey="scope"
                items={[
                  {
                    key: "scope",
                    label: <T1 pos={"analysis.graph.scopebar.title"} />,
                    planFilter: "analysisScopeChart",
                    children: (
                      <EmissionsScopeChart
                        data={dataSource || []}
                        typeOfChart={typeOfChart}
                        chartOptions={chartOptions}
                      />
                    ),
                  },
                  // {
                  //   key: "bar",
                  //   label: <T1 pos={"analysis.graph.bar.title"} />,
                  //   children: (
                  //     <EmissionsBarChart
                  //       data={dataSource || []}
                  //       chartOptions={chartOptions}
                  //     />
                  //   ),
                  // },
                  {
                    key: "pie scope",
                    label: <T1 pos={"analysis.graph.piescope.title"} />,
                    planFilter: "analysisPieScopeChart",
                    children: (
                      <ErrorBoundary>
                        <EmissionsScopePieChart
                          filters={scopePieChartFilters || {}}
                          scopePieChartElem={scopePieChartElem}
                          data={dataSource || []}
                        />
                      </ErrorBoundary>
                    ),
                  },
                  {
                    key: "pie",
                    label: <T1 pos={"analysis.graph.pie.title"} />,
                    planFilter: "analysisPieChart",
                    children: (
                      <EmissionsPieChart
                        type={typeOfChart}
                        data={dataSource || []}
                      />
                    ),
                  },
                ].filter((item) => {
                  if (item.planFilter && planValue(item.planFilter) === 0) {
                    return false;
                  }
                  return !hidden.includes(item.key);
                })}
              />
            </Boxed>
          </div>
        </ErrorBoundary>
      }
    </div>
  );
}
