import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  designations,
  findCompanies,
  findSitesInCompanies,
  getCurrentUserDesignation,
  openSites,
} from "../../../helper/attribute";
import { selectResults } from "../../../redux/features/appSlice";
import Boxed from "./comp/boxed";
import ExportButton from "./comp/exportbutton";
import Heading from "./comp/heading";
import Options from "./comp/options";
import { createcols, createGraphComponent, tableProps } from "./config";

import "./style.css";
import { getAnalysis } from "./workers/tags";

const TagAnalysis = ({ graphOnly = false }) => {
  const [options, setOptions] = useState({
    wtt: "Included",
    type: "Location",
    reporting: "All",
    year: 2024,
    monthRange: "All Year",
    reportType: "Emissions",
    sites:
      getCurrentUserDesignation() !== designations.group_admin
        ? [findCompanies()[0].id]
        : findCompanies().map((c) => c.id),
  });
  const [dataSource, setDataSource] = useState(undefined);
  const [cols, setCols] = useState([]);
  const [scopeChart, setScopeChart] = useState([]);
  let results = useSelector(selectResults);

  // calculate result
  const setResults = (message) => {
    if (typeof message === "object" && message?.data?.dataSource) {
      setDataSource(message.data.dataSource);
      setCols(message.data.moreInfo.cols);
      setScopeChart(message.data.moreInfo.scopechart);
      setGraphFilters({ col: message.data.moreInfo.cols[0] });
    }
  };
  useEffect(() => {
    setDataSource(undefined);
    if (results && getAnalysis) {
      const sites = findSitesInCompanies(options.sites);
      results = results.filter((result) => sites.includes(result.siteId));
      setResults({ data: getAnalysis(results, options, openSites()) });
    }
  }, [results, options]);
  const [graphFilters, setGraphFilters] = useState({
    col: undefined,
  });

  const columns = createcols(cols, dataSource, options);
  const graphComponent = createGraphComponent(
    scopeChart,
    graphFilters,
    setGraphFilters,
    cols,
    "Site Tags",
    { xLabel: "Tags" }
  );
  if (graphOnly) return graphComponent;
  return (
    <div className="page">
      <Heading type={"Site Tags Analysis"} />
      <Options options={options} setOptions={setOptions} />
      {graphComponent}
      <Boxed title={"Results Breakdown"}>
        <ExportButton
          dataSource={dataSource}
          fileName={"Sustrax Carbon Footprint Export"}
          cols={cols}
          options={options}
        />
        <Table {...tableProps(dataSource, results, columns, cols)} />
      </Boxed>
    </div>
  );
};

export default TagAnalysis;
