import React, { useRef } from "react";
import { FaFileUpload, FaPlusCircle, FaFileAlt, FaTrashAlt } from "react-icons/fa";
import { message, Typography } from "antd";

const { Text, Title } = Typography;

const FileSizeDisplay = ({ size }) => {
  const formatSize = (size) => {
    if (size < 1024) return `${size} Bytes`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024) return `${(size / 1024 / 1024).toFixed(2)} MB`;
    else return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  };

  return <>{size && <Text>{formatSize(size)}</Text>}</>;
};

const DataFileMultiple = ({ files, setFiles, fileSize = 1, accept = "*", minHeight, maxFiles = 1 }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (files.length + selectedFiles.length > maxFiles) {
      message.warning(`You can only upload up to ${maxFiles} file(s) in total.`);
      return;
    }

    const validFiles = selectedFiles.filter((file) => file.size / 1024 / 1024 <= fileSize);
    if (validFiles.length !== selectedFiles.length) {
      message.error(`Some files exceed the maximum size of ${fileSize} MB.`);
    }

    setFiles([...files, ...validFiles]);
  };

  const handleAddMoreClick = (e) => {
    e.stopPropagation();
    if (files.length < maxFiles) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveFile = (e, index) => {
    e.stopPropagation();
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (files.length < maxFiles) {
      handleFileChange(e);
    }
  };

  return (
    <div
      style={{
        minHeight: minHeight || "150px",
        border: "2px dashed #ccc",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: files.length < maxFiles ? "pointer" : "not-allowed",
      }}
      onClick={() => {
        if (files.length < maxFiles) {
          fileInputRef.current.click();
        }
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      <Title level={5} style={{ marginBottom: "10px" }}>
        <FaFileUpload style={{ marginRight: "8px" }} />
        Upload Files
      </Title>

      {files.length > 0 ? (
        <div style={{ width: "100%" }}>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {files.map((file, index) => {
              let href = "#";
              let filename = file.name ?? file;
              if (typeof file === "string") {
                href = "#";
              } else if (file instanceof File) {
                href = URL.createObjectURL(file);
              }
              return (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    borderBottom: "1px solid #eee",
                    paddingBottom: "5px",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <a
                    href={href}
                    download={filename}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      color: "#007bff",
                      width: "80%",
                    }}
                  >
                    <FaFileAlt style={{ marginRight: "8px" }} />
                    <Text ellipsis>{filename}</Text>
                  </a>
                  <span>
                    {file.size && <FileSizeDisplay size={file.size}/>}
                    <FaTrashAlt
                      style={{
                        color: "red",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleRemoveFile(e, index)}
                    />
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <Text>Click or drag files here to upload</Text>
      )}

      {files.length < maxFiles && (
        <Text
          style={{
            marginTop: "10px",
            color: "#007bff",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleAddMoreClick}
        >
          <FaPlusCircle style={{ marginRight: "5px" }} />
          {files.length === 0 ? "Add Files" : "Add More Files"}
        </Text>
      )}

      <input
        type="file"
        ref={fileInputRef}
        accept={accept}
        style={{ display: "none" }}
        multiple={maxFiles > 1}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default DataFileMultiple;
