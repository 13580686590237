import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import AutoCompleteTextArea from "./AutoCompleteTextArea";

const configx = {
  verifier: {
    suggestions: [
      "Evidence provided for data entered, with actual figures used.",
      "Evidence provided for data entered, with estimated figures used.",
      "No data available, estimated based on industry standards.",
      "Source provided for data, no evidence available.",
      "Estimated, based on typical business operation.",
      "Estimated.",
    ],
  },
};

const SimpleEditableTable = ({ dataSource, setDataSource, config = configx }) => {
  const handleInputChange = (value, recordKey, column) => {
    setDataSource((prevData) => prevData.map((item) => (item.key === recordKey ? { ...item, [column]: value } : item)));
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      render: (text, record) => <AutoCompleteTextArea value={text} suggestions={[]} onChange={(value) => handleInputChange(value, record.key, "data")} />,
    },
    {
      title: "Source of data",
      dataIndex: "source",
      key: "source",
      render: (text, record) => <AutoCompleteTextArea value={text} suggestions={config.source?.suggestions} onChange={(value) => handleInputChange(value, record.key, "source")} />,
    },
    {
      title: "Verifier comments",
      dataIndex: "verifier",
      key: "verifier",
      render: (text, record) => <AutoCompleteTextArea value={text} suggestions={config.verifier?.suggestions} onChange={(value) => handleInputChange(value, record.key, "verifier")} />,
    },
  ];

  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
};

SimpleEditableTable.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      data: PropTypes.string,
      source: PropTypes.string,
      verifier: PropTypes.string,
    })
  ).isRequired,
  setDataSource: PropTypes.func.isRequired,
  config: PropTypes.shape({
    data: PropTypes.shape({
      suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    source: PropTypes.shape({
      suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    verifier: PropTypes.shape({
      suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
};

export default SimpleEditableTable;
