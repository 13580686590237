import { Modal, Select, Space } from "antd";
import React, { useState } from "react";
import { updateUser } from "../../../firebase/user/update";
import { except } from "../../../firebase/util";
import { findNotAdminsOfCompany } from "../../../helper/attribute";
export default function AdminAddCompany({ visible, setVisible, _ }) {
  const users = findNotAdminsOfCompany(_);
  const [data, setData] = useState({});
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = () => {
    except(async () => {
      let admins = users.find((e) => e.id === data.adminId).admin_of_companies;
      if (!admins) admins = [];

      await updateUser(data.adminId, { admin_of_companies: [...admins, _.id] });
      setVisible(false);
    });
  };
  return (
    <Modal
      title="Add new Admin"
      open={visible}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Space style={{ paddingTop: 30 }}>
        Select User
        <Select
          value={data.adminId}
          onChange={(adminId) => setData({ ...data, adminId })}
          style={{ width: 300 }}
        >
          {users.map((user) => (
            <Select.Option value={user.id}>
              {user?.firstName} {user?.lastName}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </Modal>
  );
}
