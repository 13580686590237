import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Input, message, Modal, Space, Table } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../../firebase/user/update";
import { catcher, except } from "../../../firebase/util";
import { designations, findSitesByCompanyId, getCurrentUserDesignation, getDesignation, userCanEditSite } from "../../../helper/attribute";
import { generateRandomString, matchObjects, removeDuplicates } from "../../../helper/wodash";

function EditUserModal({ _, visible, setVisible }) {
  const navigate = useNavigate();
  const [status, setStatus] = useState({});
  const [desig, setDesig] = useState(getDesignation(_, false));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ..._,
  });

  const makeCompanyAdmin = () => {
    setData({
      ...data,
      admin_of_companies: [...removeDuplicates([...data?.admin_of_companies, _.companyId])],
      // admin_of_sites: [],
      // viewer_of_sites: [],
    });
  };
  const onOk = async () => {
    catcher(
      async () => {
        await updateUser(_.id, data);
        // navigate(routePath.UserDetails(id));
        setVisible(false);
      },
      { setLoading }
    );
  };
  const userRoleTableRows = [
    {
      title: "",
      dataIndex: "title",
    },
    {
      title: "Site Admin",
      render: (site) => {
        const checked = data?.admin_of_sites?.includes(site.id);
        return (
          <Checkbox
            checked={checked}
            disabled={!userCanEditSite(site)}
            onChange={(e) =>
              setData({
                ...data,
                admin_of_sites: e.target.checked ? [...data?.admin_of_sites, site.id] : data?.admin_of_sites.filter((id) => id !== site.id),
              })
            }
          />
        );
      },
    },
    {
      title: "Editor",
      render: (site) => {
        const isAdmin = data?.admin_of_sites?.includes(site.id);
        const isEditor = data?.editor_of_sites?.includes(site.id);
        if (isAdmin) return <Checkbox checked={true} disabled={true} />;
        else
          return (
            <Checkbox
              checked={isEditor}
              onChange={(e) =>
                setData({
                  ...data,
                  editor_of_sites: e.target.checked ? [...data?.editor_of_sites, site.id] : data?.editor_of_sites.filter((id) => id !== site.id),
                })
              }
            />
          );
      },
    },
    {
      title: "Viewer",
      render: (site) => {
        const isAdmin = data?.admin_of_sites?.includes(site.id);
        const isEditor = data?.editor_of_sites?.includes(site.id);
        const isViewer = data?.viewer_of_sites?.includes(site.id);
        if (isAdmin || isEditor) return <Checkbox checked={true} disabled={true} />;
        else
          return (
            <Checkbox
              checked={isViewer}
              onChange={(e) =>
                setData({
                  ...data,
                  viewer_of_sites: e.target.checked ? [...data?.viewer_of_sites, site.id] : data?.viewer_of_sites.filter((id) => id !== site.id),
                })
              }
            />
          );
      },
    },
  ];

  const userRoleTableDataSource = () => {
    return findSitesByCompanyId(data?.companyId).map((site) => ({
      key: site.id,
      ...site,
    }));
  };

  return (
    <>
      <Modal
        key={visible}
        title="Edit User details"
        okButtonProps={{
          disabled: matchObjects(_, data),
          loading: loading,
        }}
        okText={
          <span>
            Make Changes <ArrowRightOutlined />{" "}
          </span>
        }
        open={visible}
        onCancel={() => {
          setData({ ..._ });
          setVisible(false);
        }}
        onOk={onOk}
      >
        <Divider>
          <i>Edit user details</i>
        </Divider>

        <Space direction="vertical">
          <Space>
            <Input value={data?.firstName} onChange={(e) => setData({ ...data, firstName: e.target.value })} status={status.title === "error" && "error"} addonBefore={"First Name"} />
            <Input value={data?.lastName} onChange={(e) => setData({ ...data, lastName: e.target.value })} status={status.title === "error" && "error"} addonBefore={"Last Name"} />
          </Space>
          <Input value={data?.phone} onChange={(e) => setData({ ...data, phone: e.target.value })} status={status.phone === "error" && "error"} addonBefore={"Phone"} placeholder="+123456" />
          <Input addonBefore={"Email"} value={data?.email} onChange={() => message.info("To edit user's email, please mail us on info@carbonfootprint.com")} />

          <Divider>
            <i>Edit user roles</i>
          </Divider>
          {[designations.company_admin, designations.super_admin, designations.group_admin]?.includes(getCurrentUserDesignation()) && (
            <Checkbox
              checked={designations.company_admin === desig}
              onChange={(e) => {
                if (e.target.checked) {
                  setDesig(designations.company_admin);
                  makeCompanyAdmin();
                } else {
                  setData({
                    ...data,
                    admin_of_companies: data?.admin_of_companies.filter((company) => company !== _.companyId),
                  });
                  setDesig("Other");
                }
              }}
            >
              Company Admin
            </Checkbox>
          )}
          {/*User roles*/}
          {desig !== designations.company_admin && <Table bordered columns={userRoleTableRows} dataSource={userRoleTableDataSource()}></Table>}
        </Space>
      </Modal>{" "}
    </>
  );
}

export default function EditUser({ _, visible, setVisible }) {
  return (
    <>
      <span type="ghost" id="edit-user-button" onClick={() => setVisible(generateRandomString(10))} style={{ fontSize: "20px" }}>
        <EditOutlined />
      </span>
      <EditUserModal key={visible} visible={visible} setVisible={setVisible} _={_}></EditUserModal>
    </>
  );
}
