import { EditOutlined, MailOutlined, OrderedListOutlined, PhoneOutlined, PlayCircleTwoTone, PlaySquareTwoTone } from "@ant-design/icons";
import { Button, Checkbox, Modal, Popconfirm, Space, Table, Tag, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../../appconfig/texts";
import { CompanyPageLink } from "../../../components/Link/Link";
import Loading from "../../../components/loading";
import UserAvatar from "../../../components/User/avatar";
import { fetchUsers } from "../../../firebase/user/get";
import { updateUser } from "../../../firebase/user/update";
import { catcher } from "../../../firebase/util";
import { designations, findCompany, getCurrentUserDesignation, getDesignation } from "../../../helper/attribute";
import { selectUsers } from "../../../redux/features/appSlice";
import { selectUserDoc } from "../../../redux/features/userSlice";
import AddUserModal from "./add";
import DeleteUser from "./delete";
import EditUser from "./edit";
import { getColumnSearchProps } from "../../../components/table/tableProps";
import axios from "axios";
import { handleSignInWithCustomToken } from "../../../auth/login";
import { getDropdownFilterProps } from "../../../components/table/tableProps";
import store from "../../../redux/store";

const AdminList = ({ _ }) => {
  return <div className="link">{getDesignation(_, false)}</div>;
};

export default function Users({ canAdd = true, filterByCompanyId }) {
  let users = useSelector(selectUsers);
  if (filterByCompanyId && users) {
    users = users.filter((user) => user.companyId === filterByCompanyId);
  }
  const [loading, setLoading] = useState(false);
  const userDoc = useSelector(selectUserDoc);
  const [modals, setModals] = useState({ add: false, update: false, edit: false });
  const navigate = useNavigate();
  const setModal = (whichOne, status = new Date().getTime()) => {
    modals[whichOne] = status;
    setModals({ ...modals });
  };
  useEffect(() => {
    if (!users) fetchUsers();
  }, []);

  const handleLoginAsUser = (user) => {
    const company = findCompany(user.companyId);
    console.log(user);
    catcher(async () => {
      const ok = window.confirm("Do you want to login as " + user.firstName + " of " + company?.title + "?");
      if (ok) {
        const { data } = await axios.post(process.env.REACT_APP_BE + "/api/data/getUserToken", {
          uid: user.id,
        });
        console.log("data", data);
        if (data?.token) {
          await handleSignInWithCustomToken(data.token);
        }
      }
    }, {});
  };
  const toggleUserTwoFA = async (user, e) => {
    catcher(
      async () => {
        const requireTwofa = user.requireTwofa;
        await updateUser(user.id, {
          requireTwofa: requireTwofa ? false : true,
        });
      },
      {
        setLoading,
        success_msg: user?.requireTwofa ? "Removed Two Factor Authentication" : "Added Two Factor Authentication",
      }
    );
  };
  const handleDisplayRaw = (obj) => {
    const Pair = ({ name, value }) => (
      <div>
        {name} = {value}
        <br />
      </div>
    );
    Modal.info({
      title: "Raw user information",
      content: (
        <>
          {Object.keys(obj).map((key) => (
            <Pair name={key} value={obj[key]} />
          ))}
        </>
      ),
    });
  };

  const columns = [
    {
      title: <T1 pos="users.table.name" />,
      render: (_) => (
        <Space>
          <UserAvatar _={_} />
          <span translate="no" style={{ fontWeight: 600 }}>
            {_.firstName + " " + _.lastName}
          </span>
        </Space>
      ),
      width: 120,
      ...getColumnSearchProps("firstName", (_) => _.firstName + " " + _.lastName),
    },
    {
      title: <T1 pos="users.table.company" />,
      render: (_) => <CompanyPageLink id={_.companyId} />,
      width: 150,

      // ...getDropdownFilterProps(
      //   store.getState().app?.companies,
      //   "title",
      //   "id",
      //   "companyId"
      // ),
      // want to create a custom input render
    },
    {
      title: <T1 pos="users.table.status" />,
      render: (_) => <AdminList _={_} />,
      width: 150,
    },
    {
      title: <T1 pos="users.table.contact" />,
      render: (_) => (
        <Space direction="vertical">
          <Space>
            <MailOutlined />
            <a href={`mailto:${_.email}`} target={"_blank"} rel="noreferrer">
              {_.email}
            </a>
          </Space>
          {_.phone && (
            <Space>
              <PhoneOutlined />
              <a href={`tels:${_.phone}`} target={"_blank"} rel="noreferrer">
                {_.phone}
              </a>
            </Space>
          )}
        </Space>
      ),
      width: 120,
      ...getColumnSearchProps("contact", (_) => _.phone + " " + _.email),
    },
    {
      title: (
        <>
          <T1 pos="users.table.twofa" />
        </>
      ),
      width: 100,
      render: (user) => {
        return (
          <Popconfirm title={user.requireTwofa ? "Remove Two Factor Authentication" : "Add Two Factor Authentication"} onConfirm={() => toggleUserTwoFA(user)}>
            <Checkbox onChange={(e) => e.preventDefault()} checked={user.requireTwofa} />
          </Popconfirm>
        );
      },
    },
    {
      title: "Options",
      render: (user) => {
        if (user.id === userDoc.id) return <Tag>You</Tag>;
        return (
          <Space
            style={{ cursor: "pointer", fontSize: 20 }}
            // className="antRowShowOnHover"
          >
            {[designations.super_admin, designations.consultant, designations.affiliate].includes(getCurrentUserDesignation()) && (
              <>
                {/* <Tooltip title={`Login as ${user.firstName}`}> */}
                <PlaySquareTwoTone onClick={() => handleLoginAsUser(user)} twoToneColor={"green"} />
                {/* </Tooltip> */}
                <OrderedListOutlined onClick={() => handleDisplayRaw(user)} />
              </>
            )}
            <EditUser
              key={user.id}
              _={user}
              visible={modals.edit === user.id}
              setVisible={(val) => {
                setModals({ edit: val ? user.id : false });
              }}
            />
            <DeleteUser _={user} />
            {/* <ChangePasswordUser _={_} /> */}
          </Space>
        );
      },
      width: 100,
    },
  ];
  if (!users) return <Loading />;
  return (
    <>
      <Space>
        <Typography.Title level={2}>{<T1 pos="users.title" />}</Typography.Title>
        {canAdd && (
          <Button size="large" style={{ marginTop: 10 }} onClick={() => setModal("add")}>
            {"+ Add New User"}
          </Button>
        )}
      </Space>
      {/*Table */}
      <Table
        title={() => {
          return (
            <div>
              <Typography.Title level={4}>Help</Typography.Title>
              <ul style={{ cursor: "pointer" }}>
                <li
                  style={{ color: "grey" }}
                  onClick={() =>
                    Modal.info({
                      title: "Editing User Roles",
                      content: (
                        <ul>
                          <li>
                            Click the
                            <Button type="link" icon={<EditOutlined />} style={{ padding: 0 }}></Button>
                            button in the table.
                          </li>
                          <li>Select the roles you want to assign to the user.</li>
                          <li>Click the "Make Changes" button.</li>
                        </ul>
                      ),
                    })
                  }
                >
                  <i>How to edit user roles?</i>
                </li>
              </ul>
            </div>
          );
        }}
        pagination={{ pageSize: 50, position: ["bottomRight"] }}
        loading={loading}
        dataSource={users}
        columns={columns}
        rowKey={"id"}
      />
      {/*Modals*/}
      <AddUserModal
        key={modals.add}
        visible={modals.add}
        openEditUser={(userId) => {
          console.log(userId, "created");
          setModal("edit", userId);
        }}
        setVisible={(v) => setModal("add", v)}
      />
    </>
  );
}
