import { Divider, Empty, Modal, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Select1 from "../select/select1";
import "./style.css";
import { findSite, firstCompany } from "../../helper/attribute";
import { getNextTwelveMonths, timeFormat } from "../../helper/time";
import { extractYears, getResultDates } from "../../pages/results/resultsCalcs";
import DownloadDomImage from "../canvas/downloadDomImage";
import { getFormName } from "../../pages/extras/testing/config";
import { graph_colors } from "../../pages/extras/testing/charts/config";
import moment from "moment";
import { cleanArray, fixDecimals, removeDuplicates, removePropsFromArray } from "../../helper/wodash";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/features/appSlice";

let currentYearCacher = undefined;

let itemColorMapping = {};

function fillMissingMonths(data) {
  //CHAT GPT CODE USED IN AGGREGATE BY MONTHS
  const newData = [];

  data.forEach((entry) => {
    const { name } = entry;
    const date = moment(name, "MMM YYYY");
    const year = date.year();

    for (let month = 0; month < 12; month++) {
      const fullName = moment().year(year).month(month).format("MMM YYYY");

      if (!data.some((item) => item.name === fullName) && !newData.some((item) => item.name === fullName)) {
        newData.push({ name: fullName, result: "0" });
      }
    }
  });

  // Combine the original data and the new data, sorting by name
  return [...data, ...newData].sort((a, b) => moment(a.name, "MMM YYYY").valueOf() - moment(b.name, "MMM YYYY").valueOf());
}
export default function BarReChart({ form, results, heading, fullDataRows = [], segregate_by = false, defaultFilters }) {
  let YAXIS_OPTIONS = ["tCO₂e", "kWh", "Count"];
  let XAXIS_OPTIONS = ["Months", "Fiscal Year", "Calendar Year"];

  // OPTIONS FILTERS

  // YAXIS_OPTIONS

  if (Array.isArray(results) && results[0] && results[0].hasOwnProperty("kwh")) {
  } else {
    YAXIS_OPTIONS = YAXIS_OPTIONS.filter((option) => option !== "kWh");
    console.log(results, YAXIS_OPTIONS);
  }

  // XAXIS_OPTIONS

  if (firstCompany()?.reporting_year_starting_month === "January") {
    XAXIS_OPTIONS = XAXIS_OPTIONS.filter((x) => x !== "Calendar Year");
  }

  const [segregator, setSegrigator] = useState("none");
  const [yaxis, setYaxis] = useState("tCO₂e");
  const [xaxis, setXaxis] = useState(XAXIS_OPTIONS[0]);
  const [larged, setLarged] = useState(false);
  const [currentYear, setCurrentYear] = useState(0); // Index of the currently visible year
  const [uniqueYears, setUniqueYears] = useState([]); // Array to store unique years
  const [type, setType] = useState("Location");
  const [wtt, setWtt] = useState("Included");
  const { isDarkMode } = useSelector(selectTheme);

  useEffect(() => {
    currentYearCacher = currentYear;
  }, [currentYear]);

  // Extract unique years from data and update the state
  useEffect(() => {
    let uniqueYears = extractYears(fullDataRows);
    setUniqueYears(uniqueYears);

    setCurrentYear(currentYearCacher || uniqueYears.length - 1);
  }, [results]);

  useEffect(() => {
    if (typeof defaultFilters === "object") {
      setWtt(defaultFilters.wtt ? "Included" : "Excluded");
    }
  }, [defaultFilters]);
  
  // save results
  // useEffect(() => {
  //   const not_include_me = [];
  //   const user_saved_options = localStorage.getItem("user-saved-options:" + window.location.href);
  //   if (user_saved_options) {
  //     const options_saved = JSON.parse(user_saved_options);
  //     not_include_me.forEach((key) => {
  //       options_saved[key]=options[key]
  //     });
  //     setOptions(options_saved);
  //     setFilters(options_saved);
  //   }
  // }, []);

  // const saveSettingsAsDefault = () => {
  //   localStorage.setItem("user-saved-options:" + window.location.href, JSON.stringify(filters));
  //   message.success("Settings saved as default");
  // };

  function conv2months(form, results, chartPropsindex, decimalplaces = 2) {
    // convert simple date to months which will later be added below to display to users
    const formattedData = results.map((entryx) => {
      const entry = { ...entryx };
      let name = entry.name;
      if (xaxis === XAXIS_OPTIONS[0]) {
        name = moment(entry.date, timeFormat).format("MMM YYYY");
      } else if (xaxis === XAXIS_OPTIONS[1]) {
        name = getResultDates(entry).fiscalYear;
      } else if (xaxis === XAXIS_OPTIONS[2]) {
        name = Number(moment(entry.date, timeFormat).format("YYYY"));
      }
      if (type === "Market") {
        entry.result = entry.marketbased_result;
        entry.wtt = entry.marketbased_wtt;
      }
      if (wtt === "Excluded") {
        entry.wtt = 0;
      }
      return {
        ...entry,
        name,
        // result,
        // used in yaxis
        "tCO₂e": (entry.result ?? 0) + (entry.wtt ?? 0),
        kWh: entry.kwh ?? 0,
        Count: 1,
        // used in breakdown
        siteCountry: findSite(entry.siteId).country,
        site: findSite(entry.siteId).title,
      };
    });

    if (xaxis === XAXIS_OPTIONS[0]) return fillMissingMonths(formattedData);
    else return formattedData;
  }
  results = conv2months(form, results);

  let graphBarNames = getNextTwelveMonths(firstCompany()?.reporting_year_starting_month, uniqueYears[currentYear], "YYYY", "MMM").map((obj) => {
    return obj.month + " " + obj.year;
  });
  if (xaxis !== XAXIS_OPTIONS[0]) graphBarNames = uniqueYears;

  console.log("Data: ", results);

  const graphDataRaw = graphBarNames.map((monthName) => {
    const filteredData = results.filter((item) => item.name === monthName);
    const result = { name: monthName };

    for (let i = 0; i < filteredData.length; i++) {
      const curr = filteredData[i];
      let typeKey = yaxis;

      if (segregator !== "none" && curr[segregator]) {
        typeKey = curr[segregator];
      }

      if (!result[typeKey]) {
        result[typeKey] = 0;
      }

      result[typeKey] += Number(curr[yaxis]);
    }

    // Ensure two decimals for all numeric values
    for (const key in result) {
      if (key !== "name" && typeof result[key] === "number") {
        result[key] = fixDecimals(result[key], 2);
      }
    }

    return result;
  });
  const graphData = cleanArray(graphDataRaw, [undefined, null, NaN]);
  console.log("Graph data", graphData);

  const renderStackedBarChart = () => {
    console.log(graphData);
    let types = [...new Set(results.map((item) => item[segregator]))];

    if (segregator === "none") {
      types = [yaxis];
    }

    return (
      <BarChart margin={{ bottom: 50, left: 40, top: 10, right: 30 }} data={graphData}>
        <CartesianGrid />
        <XAxis dataKey="name" fontSize={11} interval={0}>
          <Label value={xaxis} position="insideBottom" offset={-10} fill="#000000" style={{ textDecoration: "underline" }} />
        </XAxis>
        <YAxis>
          <Label value={yaxis} angle={-90} position="insideLeft" offset={-20} fill="#000000" style={{ textDecoration: "underline" }} />
        </YAxis>
        <Tooltip />
        <Legend verticalAlign="top" height={36} />
        {types
          .filter((x) => ![undefined, null].includes(x))
          .map((type, index) => {
            // Assign a color to the item if it doesn't already have one
            if (!itemColorMapping[type]) {
              const colorIndex = Object.keys(itemColorMapping).length % graph_colors.length;
              itemColorMapping[type] = graph_colors[colorIndex];
            }
            const color = itemColorMapping[type];
            return <Bar key={type + index} dataKey={type} stackId="a" fill={color} name={type} />;
          })}
      </BarChart>
    );
  };

  const chartElement = segregator === "nonee" ? renderStackedBarChart() : renderStackedBarChart();

  if (!results?.length) return <Empty description="Graph Construction Error: No Data Detected" style={{ background: "white", padding: 20 }} />;

  const segrigate_filters = [
    { key: "Default", value: "none" },
    { key: form?.name === "hotel" ? "Site Country" : "Country", value: "siteCountry" },
    { key: "Site", value: "site" },
    ...segregate_by,
  ].filter(({ value }) => {
    // hide options if they will have same value eg all results have country United Kingdom then hide country
    if (value !== "none") {
      const valuesForOption = removeDuplicates(
        results.map((result) => {
          if (value === "siteCountry") {
            return findSite(result.siteId).country;
          }
          return result[value];
        })
      ).filter((x) => x);
      if (valuesForOption.length < 2) return false;
    }
    return true;
  });
  console.log(segrigate_filters, "segrigate_filters");

  return (
    <>
      {heading && <Divider>{heading}</Divider>}
      <div
        style={{
          background: theme.useToken().token.colorBgContainer,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div className="navigation-arrows">
            {xaxis === XAXIS_OPTIONS[0] && (
              // only display this if xaxis is set to months
              // hide if one year option only i.e. all are in 2024
              <Select1
                hideIfOneOptionOnly
                minWidth={"105px"}
                title="Year"
                value={uniqueYears[currentYear]}
                setValue={(year) => setCurrentYear(uniqueYears.findIndex((x) => x === year))}
                options={uniqueYears}
              />
            )}
            {Array.isArray(segregate_by) && (
              <div className="navigation-arrows">
                <Select1 hideIfOneOptionOnly minWidth={"105px"} title={<>Breakdown by</>} value={segregator} setValue={setSegrigator} options={segrigate_filters} />
              </div>
            )}
            <Select1 hideIfOneOptionOnly minWidth={"105px"} title="WTT" value={wtt} setValue={setWtt} options={["Included", "Excluded"]} />
            {form.name === "electricity" && <Select1 hideIfOneOptionOnly minWidth={"105px"} title="Type" value={type} setValue={setType} options={["Location", "Market"]} />}
            <Select1 hideIfOneOptionOnly minWidth={"105px"} title="Y Axis" value={yaxis} setValue={setYaxis} options={YAXIS_OPTIONS} />
            <Select1 hideIfOneOptionOnly minWidth={"105px"} title="X Axis" value={xaxis} setValue={setXaxis} options={XAXIS_OPTIONS} />
          </div>

          <DownloadDomImage
            fileName={`Chart Export ${firstCompany()?.title}.png`}
            elementId={`bar-rechart-image-${JSON.stringify(results)}`}
            text={"Graph of Results: " + getFormName(form?.name) + ", Year: " + uniqueYears[currentYear] + "\nCreated by Sustrax MX from CarbonFootprint.com"}
          />
        </div>

        <div style={{ position: "relative", padding: 10 }}>
          <ResponsiveContainer id={`bar-rechart-image-${JSON.stringify(results)}`} aspect={5}>
            {chartElement}
          </ResponsiveContainer>
        </div>
        <Modal title="Graph Enlarged View" footer={null} onCancel={() => setLarged(false)} style={{ height: "100vh" }} open={larged} width="100wh">
          <ResponsiveContainer aspect={1}>{chartElement}</ResponsiveContainer>
        </Modal>
      </div>
    </>
  );
}
