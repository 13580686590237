import {
  CloseCircleFilled,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Modal, Space, Table, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../../appconfig/texts";
import Loading from "../../../components/loading";
import UserAvatar from "../../../components/User/avatar";
import { updateUser } from "../../../firebase/user/update";
import { except } from "../../../firebase/util";
import { findAdminsOfCompany } from "../../../helper/attribute";
import { selectUserDoc } from "../../../redux/features/userSlice";
import AddAdminCompany from "./admin_add";
export default function CompanyAdmins({ _ }) {
  let title = <T1 pos="company.companyAdmins.title" />;
  const navigate = useNavigate();
  const userdoc = useSelector(selectUserDoc);
  const [modals, setModals] = useState({ add: false, update: false });
  const setModal = (whichOne, status = new Date().getTime()) => {
    modals[whichOne] = status;
    setModals({ ...modals });
  };

  const handleDissmissAdmin = (user) => {
    Modal.confirm({
      onOk: () =>
        except(async () => {
          let admins = user.admin_of_companies;
          if (!admins) admins = [];
          await updateUser(user.id, {
            admin_of_companies: admins.filter(
              (companyId) => companyId !== _.id
            ),
          });
          navigate("/companies");
        }),
      title: `Dismiss as admin`,
      content: `Do you want to ${user.firstName} as admin of ${_.title}?`,
    });
  };

  let columns = [
    {
      title: <T1 pos="company.companyAdmins.table.name" />,
      render: (_) => (
        <Space>
          <UserAvatar _={_} />
          <span style={{ fontWeight: 600 }}>
            {_.firstName + " " + _.lastName}
          </span>
        </Space>
      ),
      width: 120,
    },
    {
      title: <T1 pos="company.companyAdmins.table.contact" />,
      render: (_) => (
        <Space direction="vertical">
          <Space>
            <MailOutlined />
            <a href={`mailto:${_.email}`} target={"_blank"} rel="noreferrer">
              {_.email}
            </a>
          </Space>
          {_?.phone && (
            <Space>
              <PhoneOutlined />
              <a href={`tels:${_.phone}`} target={"_blank"} rel="noreferrer">
                {_.phone}
              </a>
            </Space>
          )}
        </Space>
      ),
      width: 120,
    },
    {
      title: "",
      render: (_) =>
        userdoc.id != _.id ? (
          <Space
            style={{ cursor: "pointer", fontSize: 20 }}
            className="antRowShowOnHover"
          >
            <Tooltip title="Dismiss as admin?">
              <CloseCircleFilled
                onClick={() => handleDissmissAdmin(_)}
                style={{ color: "darkred" }}
              />
            </Tooltip>
          </Space>
        ) : (
          "(You)"
        ),
      width: 120,
    },
  ];
  const users = findAdminsOfCompany(_);
  if (!users) return <Loading />;
  return (
    <>
      <Space>
        <Typography.Title level={3}>{title}</Typography.Title>
        <Button onClick={() => setModal("add")}>+ Add</Button>
      </Space>
      {/*Table */}
      <Table dataSource={users} columns={columns} rowKey={"id"} />
      {/*Modals*/}
      <AddAdminCompany
        key={modals.add}
        visible={modals.add}
        setVisible={(v) => setModal("add", v)}
        _={_}
      />
    </>
  );
}
