import useSize from "../../../../hooks/useSize";
import React, { useState } from "react";
import { Modal, DatePicker, Button, message, Typography, Divider, Spin } from "antd";
import moment from "moment";
import axios from "axios";
import { designations, findCompanies, firstCompany, getCurrentUserDesignation } from "../../../../helper/attribute";
import Year from "../../../analysis/year";
import YearAnalysis from "../year.analysis";
import store from "../../../../redux/store";
import { getAnalysis } from "../workers/year";
import { getCurrentYear, timeFormat } from "../../../../helper/time";
import cadilogo from "./cadilogo.png";
import cadiFulllogo from "./cadiFull.png";
import "./style.css";
import { ArrowRightOutlined, CheckCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { downloadFile } from "../../../../helper/wodash";
import { isBad } from "../../../../helper/profanity";
import { updateUserClicks } from "../../../../firebase/user/update";
const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

export function calculateTotals(data, year, returnCompleteTotalOnly = false) {
  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;
  let nameTotals = {};
  for (let i = 1; i != 21; i++) {
    nameTotals[i] = 0;
  }
  console.log(nameTotals);

  data.forEach((scope) => {
    if (scope.children) {
      scope.children.forEach((child) => {
        if (child[year]) {
          switch (child.scope) {
            case 1:
              scope1 += child[year];
              break;
            case 2:
              scope2 += child[year];
              break;
            case 3:
              scope3 += child[year];
              break;
          }
          if (!nameTotals[child.name]) {
            nameTotals[child.name] = 0;
          }
          nameTotals[child.name] += child[year];
        }
      });
    }
  });
  if (returnCompleteTotalOnly) return scope1 + scope2 + scope3;
  return {
    scope1Total: scope1,
    scope2Total: scope2,
    scope3Total: scope3,
    nameTotals,
  };
}

const CarbondiButton = ({ handlePublish }) => {
  const { isExtraSmall } = useSize();
  {
    /**code by myles howard +wasil */
  }

  updateUserClicks("cadi_publish_btn")
  return (
    <div
      onClick={() => {
        window.open("https://carbondi.com/", "_blank");
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#3c9ee1",
          padding: "2px 0",
          borderBottom: "1px solid #e7e7e7",
        }}
      >
        <img
          src="https://s3-eu-west-1.amazonaws.com/assets.knack-eu.com/assets/64b841fbebd4370028a6cf26/logos/cadi4.png"
          alt="Application Logo"
          style={{ width: "75px", height: "65px", marginRight: "20px" }}
        />
        <span style={{ fontSize: isExtraSmall ? "12px" : "32px", color: "#ffffff" }}>Publish your results to the Carbon Database Initiative&nbsp;</span>
        <div
          onClick={handlePublish}
          target="_blank"
          style={{
            textAlign: "center",
            color: "#ffffff",
            transition: "background-color 0.1s ease 0s",
            boxSizing: "inherit",
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            lineHeight: "1",
            userSelect: "none",
            minWidth: "64px",
            padding: "10px 20px",
            borderRadius: "50px",
            fontSize: isExtraSmall ? "12px" : "17px",
            backgroundColor: "#2571ae",
          }}
        >
          Publish
        </div>
      </div>
    </div>
  );
};

const BannerCarbondi = () => {
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const { isMobile } = useSize();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePublishClick = (e) => {
    e.stopPropagation();
    showModal();
  };

  const handleDateChange = (_, dates) => {
    console.log("setting dates", dates);
    setStartDate(dates ? dates[0] : null);
    if (dates && ["", undefined, null].includes(dates[1])) {
      function addYearSubtractDay(dateString) {
        const formattedDate = moment(dateString, timeFormat);
        const newDate = formattedDate.add(1, "years").subtract(1, "days");
        return newDate.format(timeFormat);
      }
      setEndDate(addYearSubtractDay(dates[0]));
    } else {
      setEndDate(dates ? dates[1] : null);
    }
  };
  console.log(startDate, endDate);

  const handleSubmit = async () => {
    const companyName = firstCompany()?.title;
    if (isBad(companyName)) {
      console.log("bad name found", companyName);
      return message.error("Bad word found in company name. Please contact us if this is not correct.");
    }
    if (!startDate || !endDate) {
      message.error("Please select both start and end dates");
      return;
    }

    setLoading(true);

    const url = "https://api.knack.com/v1/objects/object_1/records";
    const appId = "64b841fbebd4370028a6cf26";
    const apiKey = "a9ab325f-bae2-48ff-b0de-76ffbe0dbe08";

    // Helper data

    const options = {
      wtt: "Included",
      type: "Location",
      reporting: "All",
      sites: getCurrentUserDesignation() !== designations.group_admin ? [findCompanies()[0]?.id] : findCompanies()?.map((c) => c.id),
      reportType: "GHG",
    };
    const locationAnalysis = getAnalysis(store.getState().app.results, options);
    const marketAnalysis = getAnalysis(store.getState().app.results, {
      ...options,
      type: "Market",
    });

    const locationTotals = calculateTotals(locationAnalysis.dataSource, getCurrentYear());

    const marketTotals = calculateTotals(marketAnalysis.dataSource, getCurrentYear());
    console.log(locationTotals);

    //-- helper data
    let data = {
      field_1: companyName, // name
      field_8: `${companyName} (Sustrax Lite Transfer)`,
      field_27: endDate,
      field_28: startDate,
      field_12: locationTotals.scope1Total, // Scope 1 tCO2e
      field_25: locationTotals.scope2Total, // Scope 2 (Location Based) tCO2e
      field_47: marketTotals.scope2Total, // Scope 2 (Market Based) tCO2e
      field_26: locationTotals.scope3Total, // Scope 3 (Location Based) tCO2e
      field_79: marketTotals.scope3Total, // Scope 3 (Market Based) tCO2e
      field_148: firstCompany()?.metrics?.[getCurrentYear()]?.fte ?? 1, // FTE Employee Count
      field_156: "665eea2fd31894002943f050",
      field_311: store.getState().user.doc.email,
      // add field63=>field77 as ghg 6=>20
    };

    let ghgFields = {};
    for (let i = 63, j = 6; i <= 77; i++, j++) {
      ghgFields[`field_${i}`] = locationTotals.nameTotals[j];
    }

    const fields = [
      { field: "field_29", condition: "field_63", assessed: "Partial Assessed" },
      { field: "field_43", condition: "field_64", assessed: "Partial Assessed" },
      { field: "field_50", condition: "field_65", assessed: "Fully Assessed" },
      { field: "field_51", condition: "field_66", assessed: "Partial Assessed" },
      { field: "field_52", condition: "field_67", assessed: "Partial Assessed" },
      { field: "field_53", condition: "field_68", assessed: "Fully Assessed" },
      { field: "field_54", condition: "field_69", assessed: "Partial Assessed" },
      { field: "field_55", condition: "field_70", assessed: "Partial Assessed" },
      { field: "field_56", condition: "field_71", assessed: "Partial Assessed" },
      { field: "field_57", condition: "field_72", assessed: "Partial Assessed" },
      { field: "field_58", condition: "field_73", assessed: "Partial Assessed" },
      { field: "field_59", condition: "field_74", assessed: "Partial Assessed" },
      { field: "field_60", condition: "field_75", assessed: "Partial Assessed" },
      { field: "field_61", condition: "field_76", assessed: "Partial Assessed" },
      { field: "field_62", condition: "field_77", assessed: "Partial Assessed" },
    ];
    data = { ...data, ...ghgFields };

    fields.forEach(({ field, condition, assessed }) => {
      data[field] = [0, null, undefined, "0"].includes(data[condition]) ? "Not Assessed" : assessed;
    });

    console.log("🛜SENDING DATA TO CARBONDI:", data);
    try {
      const existingRecord = await axios.get(`${url}?filters=[{"field":"field_1","operator":"is","value":"${data.field_1}"}]`, {
        headers: {
          "X-Knack-Application-Id": appId,
          "X-Knack-REST-API-Key": apiKey,
          "Content-Type": "application/json",
        },
      });

      if (existingRecord.data.records.length > 0) {
        console.log("User exists already!!!!");
        data.field_9 = existingRecord.data.records[0].id;

        await axios.post("https://api.knack.com/v1/objects/object_3/records", data, {
          headers: {
            "X-Knack-Application-Id": appId,
            "X-Knack-REST-API-Key": apiKey,
            "Content-Type": "application/json",
          },
        });
        // message.success("Record updated successfully");
        setSaved("saved");
      } else {
        const newRecord = await axios.post(url, data, {
          headers: {
            "X-Knack-Application-Id": appId,
            "X-Knack-REST-API-Key": apiKey,
            "Content-Type": "application/json",
          },
        });
        data.field_9 = newRecord.data.id;
        data.field_146 = "Yes"; // Publish
        data.field_157 = Date.now();

        console.log("Creating records (object_3)", data);
        await axios.post("https://api.knack.com/v1/objects/object_3/records", data, {
          headers: {
            "X-Knack-Application-Id": appId,
            "X-Knack-REST-API-Key": apiKey,
            "Content-Type": "application/json",
          },
        });
        // message.success("Record created successfully");
        setSaved("published");
      }
    } catch (error) {
      console.error("Error processing record:", error);
      message.error("Error processing record");
    } finally {
      setLoading(false);
      // setVisible(false);
    }
  };

  return (
    <div>
      <div>
        <CarbondiButton handlePublish={handlePublishClick} />
      </div>
      <Modal closable={false} width={isMobile ? "100%" : "700px"} title={null} open={visible} onCancel={handleCancel} footer={null} centered className="padding-less" style={{}}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#3c9ee1", padding: "7px 7px" }}>
          <img
            src="https://s3-eu-west-1.amazonaws.com/assets.knack-eu.com/assets/64b841fbebd4370028a6cf26/logos/cadi4.png"
            alt="Application Logo"
            style={{ width: "80px", height: "65px", marginRight: "20px" }}
          />
          {/* <span style={{ fontSize: isMobile ? "12px" : "25px", color: "#ffffff" }}>Carbon Database Initiative&nbsp;</span> */}

          <a href="https://www.carbonfootprint.com/carbondi.html" target="_blank">
            <Button type="link" style={{ color: "white", fontSize: "" }}>
              Find Out More <ArrowRightOutlined />
            </Button>
          </a>
        </div>
        {!loading && !saved ? (
          <div style={{ padding: 10 }}>
            <Paragraph>
              The Carbon Database Initiative is a global free to use, free to disclose database for business/organisation carbon footprints, net zero commitments & carbon neutral claims. Click Publish
              to:
              <ul>
                <li>
                  Make your GHG results <b style={{ fontWeight: "500" }}>transparent</b>
                </li>
                <li>
                  Prove your <b style={{ fontWeight: "500" }}>commitment</b> to carbon management
                </li>
              </ul>
            </Paragraph>
            <Paragraph strong>Enter the start and end date for your data period (12 months)</Paragraph>
            <RangePicker
              value={startDate ? [dayjs(startDate, timeFormat), dayjs(endDate, timeFormat)] : null}
              onChange={handleDateChange}
              allowEmpty={[false, true]}
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
            />
            <Paragraph style={{ marginTop: 20, fontSize: 12 }}>
              To edit or add other data to CaDI please make an account at{" "}
              <a href="http://www.carbondi.com" target="_blank" rel="noopener noreferrer">
                www.carbondi.com
              </a>{" "}
              to manage your account or reach out to{" "}
              <a target="_blank" href="mailto:info@carbonfootprint.com">
                info@carbonfootprint.com
              </a>
              .
            </Paragraph>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: 4, marginTop: 20 }}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button style={{ background: "#3c9ee1", border: "none", fontSize: 17, color: "white", cursor: "pointer" }} loading={loading} onClick={handleSubmit}>
                Publish
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={cadiFulllogo} style={{ height: "100%", width: isMobile ? "0px" : "160px" }} />
                {loading ? (
                  <div>
                    <Typography.Title level={2}>
                      <Spin size="large" style={{ marginRight: "10px" }} />
                      Publishing Data to CaDI{" "}
                    </Typography.Title>
                    {<p>Please wait, this may take a moment on the first run.</p>}
                  </div>
                ) : (
                  <div>
                    <div style={{ display: "flex", justifyContent: "center", width: "fit-content", flexDirection: "column", marginBottom: "15px" }}>
                      {!saved ? <Spin></Spin> : <CheckCircleOutlined style={{ color: "#4CAF50", fontSize: "48px", marginBottom: "0px", display: "flex", justifyContent: "center" }} />}
                      <Typography.Title level={2} style={{ lineHeight: 0 }}>
                        {saved === "saved" ? "Stored on CaDI" : "Published on CaDI"}
                      </Typography.Title>
                    </div>
                    <Typography.Text>
                      {saved === "saved" ? (
                        <p>
                          As you already have a company registered on CaDI please{" "}
                          <a href="https://www.carbondi.com" target="_blank">
                            log into
                          </a>{" "}
                          your account to publish your disclosure, if you don't have an account please register for one now on CaDI and our team will link you to your company within 24 hours. if you
                          need any assistance please contact{" "}
                          <a target="_blank" href="mailto:info@carbonfootprint.com">
                            info@carbonfootprint.com.{" "}
                          </a>
                        </p>
                      ) : (
                        <>
                          <b>To view, edit or add other data to CaDI please make an account at</b>
                          <a href="www.carbondi.com"> www.carbondi.com</a> or reach out to{" "}
                          <a target="_blank" href="mailto:info@carbonfootprint.com">
                            info@carbonfootprint.com
                          </a>
                          . Download the{" "}
                          <a
                            onClick={() => {
                              downloadFile(`${process.env.REACT_APP_BE}/cadibranding`, "CaDI Branding.png");
                            }}
                          >
                            ‘onCaDI’
                          </a>{" "}
                          branding to display on your website and email signature.
                        </>
                      )}
                    </Typography.Text>
                  </div>
                )}
              </div>
              <Divider dashed />
              <div style={{ display: "flex", gap: "10px", flex: "1 1 1", width: "100%", justifyContent: "space-between" }} className="footerOptions">
                <Button
                  onClick={async () => {
                    await downloadFile(`${process.env.REACT_APP_BE}/cadibranding`, "CaDI Branding.png");
                  }}
                  type="primary"
                  className="bg-blue"
                  style={{ visibility: saved === "saved" || loading ? "hidden" : "visible" }}
                >
                  {!isMobile && "Download"} Branding <DownloadOutlined />
                </Button>
                <div style={{ display: "flex", gap: 10 }}>
                  <Button type="primary" onClick={handleCancel} className="bg-grey">
                    Close
                  </Button>
                  <a href="https://www.carbondi.com" target="_blank">
                    <Button type="primary" className="bg-blue">
                      Go to CaDI <ArrowRightOutlined />
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BannerCarbondi;
