import {
  EditOutlined,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  WarningOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, theme, Typography } from "antd";
import FormContext from "../../contexts/FormContext";
import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import useSize from "../../hooks/useSize";

export default function FakeLabel({
  elem,
  title,
  info,
  description,
  animate,
  dropdownMatchSelectWidth = false,
  leftElem,
  shouldOptionTranslate = "yes",
  error_status = null,
  displayInfo = true,
  ...more
}) {
  const {isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge} = useSize();
  const contextData = useContext(FormContext);
  const {
    bgless = false,
    borderless = false,
    minWidthElem = isExtraSmall ? "63%" : isSmall ? "70%" : "230px",
    maxWidthElem = "100%",
    minWidthLabel = "80px",
    bgColor = "rgba(0, 0, 0, 0.04)",
    fixedWidth,
    justifyContent,
    error_message = "",
  } = { ...more, ...contextData };
  try {
    if (error_message.includes(title)) {
      error_status = error_message;
    }
  } catch (error) {}
  return (
    <div
      className={animate ? "slide-in-left fakelabel" : "fakelabel octagon"}
      style={{
        display: "flex",
        flexDirection: "column",
        background: bgless ? "transparent" : bgColor,
        border: borderless ? "transparent" : "1px solid #dcdee3",
        padding: more.padding || "10px",
        width: fixedWidth,
        borderRadius: more.round ? more.round : null,
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: justifyContent || "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: "600",
              marginRight: "20px",
              minWidth: minWidthLabel,
            }}
          >
            <Typography.Text>
              <span style={{ fontSize: isExtraSmall ? "13px" : "14px" }}>
                {" "}
                {title}
              </span>
            </Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              maxWidth: maxWidthElem,
              minWidth: minWidthElem,
            }}
          >
            <Tooltip
              open={false}
              color={"#f1f2f2"}
              title={info}
              placement="right"
            >
              {React.cloneElement(elem, {
                dropdownMatchSelectWidth: dropdownMatchSelectWidth,
                style: { width: "100%" },
                translate: shouldOptionTranslate,
                status: error_status ? "error" : "",
                disabled: contextData?.disabled,
              })}
            </Tooltip>
            {leftElem}
          </div>
        </div>
        {description && description !== "" && (
          <div
            style={{
              fontSize: "12px",
              color: "grey",
              marginTop: isExtraSmall ? "5px " : "",
            }}
          >
            {description}
          </div>
        )}
        {info && displayInfo && (
          <small style={{ color: "grey" }}>{info}</small>
        )}

        {error_status && (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              marginTop: isExtraSmall ? "5px" : "",
            }}
          >
            <InfoCircleOutlined /> {error_status}
          </div>
        )}
      </div>
    </div>
  );
}
