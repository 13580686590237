// Chatbot.js
import React, { useEffect, useState } from "react";
import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config, { LoadMessages as loadChat } from "./config";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Space, Watermark, message, Spin, Button, Tooltip } from "antd";
import "./style.css";
import { InfoCircleFilled, MessageOutlined, QuestionOutlined } from "@ant-design/icons";
import { updateUser } from "../../firebase/user/update";
import { useSelector } from "react-redux";
import { selectUserDoc } from "../../redux/features/userSlice";
import { updateThreadID } from "./chatbothelper";
import { isSustrax } from "../../helper/plans";

const BOT_THREAD_ID = "bot_thread_id";

function SustraxBot({ fullPage = true }) {
  const userDoc = useSelector(selectUserDoc);
  const [configData, setConfigData] = useState({ ...config });
  const [loading, setLoading] = useState(false);
  const [newChatLoading, setNewChatLoading] = useState(false);
  console.log("Thread ID: ", userDoc[BOT_THREAD_ID]);

  const newChat = async () => {
    setNewChatLoading(true);
    try {
      await updateThreadID(userDoc, userDoc[BOT_THREAD_ID], true);
      const newConfig = configData;
      configData["initialMessages"] = [createChatBotMessage("Hello! How can I help you?")];
      setConfigData(newConfig);
    } catch (err) {
      message.error("Error occured while bringing new chat up");
    } finally {
      setNewChatLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      loadChat().then((messages) => {
        console.log(messages);
        if (messages?.length > 0) {
          setConfigData({ ...config, initialMessages: messages });
        }
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      message.error("An unexpected error occured while loading messages.");
    }
  }, []);

  let styles = {};
  if (!fullPage) {
    styles = {
      position: "fixed",
      bottom: "110px",
      right: "15px",
      zIndex: 1000,
      borderRadius: "2px",
      width: "600px !important",
    };
  }
  if (!isSustrax(["demo", "pro"])) {
    return null;
  }
  return (
    <>
      <div style={{ width: "100% !important", ...styles }} className={`${fullPage ? "fullPage" : "notFullPage"} ${loading ? "ready" : "notReady"}`}>
        <Chatbot
          key={JSON.stringify({ configData })}
          config={configData}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          headerText={
            <>
              {loading ? (
                <>
                  <Spin style={{ marginRight: "10px" }} indicator={<LoadingOutlined spin />} />
                  <Space>
                    <MessageOutlined /> Loading Chats
                  </Space>
                </>
              ) : (
                <Space>
                  <MessageOutlined /> Sustrax MX{" "}
                  <Tooltip
                    showArrow
                    placement="bottomRight"
                    title="This AI chatbot is a great first step in learning about Sustrax MX. It has been trained on the layout, inputs and features of the system, but it might not always have the most up-to-date details, accuracy, or knowledge of future developments. If you need more specific help, have questions the chatbot can't answer, or want to learn about the latest features, please don't hesitate to contact your Sustrax MX account manager. They are always happy to assist you."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              )}

              <Tooltip title="Delete history and start new chat.">
                <Button size="small" loading={newChatLoading} onClick={newChat} style={{ marginLeft: "auto" }}>
                  <PlusOutlined />
                  New Chat
                </Button>
              </Tooltip>
            </>
          }
        />
      </div>
    </>
  );
}

export default SustraxBot;
