import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import axios from "axios";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountries } from "../assets/countries";
import { logout } from "../components/Siderbar";
import { auth } from "../firebase/setup";
import { signInNewUser } from "../firebase/user/create";
import { catcher } from "../firebase/util";
import { getAllParamsFromLocation } from "../helper/loc";
import { selectUserDoc, setUserDoc } from "../redux/features/userSlice";
import { adminApi } from "../static/adminApi";
import "./style.css";
import { selectAffiliate } from "../redux/features/appSlice";
import { LoginContent, Logo } from "../styles/core-components";
import store from "../redux/store";
const gprovider = new GoogleAuthProvider();

export async function handleSignInWithCustomToken(customToken) {
  try {
    const userCredential = await signInWithCustomToken(auth, customToken);
    var user = userCredential.user;
    window.location.reload();
    // Handle signed-in user
  } catch (error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // Handle the error
    throw error;
  }
}

const Login = ({ type = "simple" }) => {
  const [mode, setMode] = useState(type);
  const navigate = useNavigate();
  const [email, setName] = useState("");
  const [password, setPassword] = useState("");
  const userDoc = useSelector(selectUserDoc);
  const dispatch = useDispatch();
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [loading, setLoading] = useState(false);
  const [authCode, setAuthCode] = useState(null);
  const af = useSelector(selectAffiliate);
  useEffect(() => {
    if (userDoc && type === "simple") return navigate("/dashboard");
    if (getAllParamsFromLocation()["type"] === "register")
      return setMode("register");
    // localStorage.clear();
  }, []);
  const onFinish = async () => {
    const auth = getAuth();
    dispatch(setUserDoc(undefined));
    try {
      setLoading(true);
      const doc = await signInWithEmailAndPassword(auth, email, password);
      console.time("Loggin Time");
    } catch (err) {
      dispatch(setUserDoc(null));
      message.error("Error Logging In");
    } finally {
      setLoading(false);
    }
  };
  const handelGoogleSignIn = async () => {
    setLoadingMessage("Trying to login with google");
    const auth = getAuth();
    dispatch(setUserDoc(undefined));
    try {
      await signInWithRedirect(auth, gprovider);
    } catch (err) {
      dispatch(setUserDoc(null));
      message.error("Error Logging In");
    }
  };
  const onFinishFailed = (errorInfo) => {
    toast("Error Logging In", { type: "error" });
  };
  const onFinishSignUp = ({
    firstName,
    lastName,
    phone,
    email,
    password,
    companyName,
    country,
  }) => {
    catcher(
      async () => {
        const af = store.getState().app.affiliate;
        const data = { firstName, lastName, email, password, phone };
        await signInNewUser(data, companyName, country, af?.id);
        setName(email);
        setPassword(password);
        setMode("simple");
      },
      { setLoading }
    );
  };
  //AUTH CODE FINISH/ FAIL
  const onFinishAuthCode = () => {
    catcher(
      async () => {
        const resp = await axios.post(adminApi.twofa, {
          code: authCode,
          userId: userDoc.id,
        });

        dispatch(setUserDoc({ ...userDoc, authCode: false }));
      },
      { setLoading }
    );
  };
  const onFinishFailedAuthCode = () => {};
  const handleForgotPassword = () => {
    Modal.confirm({
      title: "Reset Password",
      content: (
        <div>
          <p>Please enter your email.</p>
          <Input id="password-reset" placeholder="someone@example.com" />
        </div>
      ),
      onOk: async () => {
        try {
          // clearing cookies as well
          const email = document.getElementById("password-reset").value;
          await axios.post(adminApi.sendPasswordResetMail, {
            email,
          });
          message.info("A password reset mail has been sent to " + email);
        } catch (err) {
          message.error("Unable to send password to this email.");
        }
      },
      okText: "Send Reset Link",
    });
  };
  const handleBack = () => {
    logout();
    setMode("simple");
  };

  const loginForm = (
    <Form
      name="login-form"
      style={{ padding: "20px" }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your Email!" }]}
      >
        <Input
          placeholder="Email"
          value={email}
          onInput={(e) => setName(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder="Password"
          value={password}
          onInput={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
      <Form.Item style={{ display: "flex", justifyContent: "right" }}>
        <div
          style={{
            color: "#0A8FDC",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={handleForgotPassword}
        >
          Forgot Your Password?
        </div>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={userDoc === undefined}
        >
          LOGIN
        </Button>
      </Form.Item>
      <a
        style={{
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "15px",
        }}
        onClick={() => setMode("register")}
      >
        or register
      </a>
    </Form>
  );
  const authForm = (
    <Form
      name="login-form"
      style={{ padding: "20px" }}
      onFinish={onFinishAuthCode}
      onFinishFailed={onFinishFailedAuthCode}
    >
      <Button
        className="small-back-link"
        style={{ marginBottom: 20 }}
        type="text"
        size="small"
        icon={<ArrowLeftOutlined />}
        onClick={handleBack}
        loading={loading}
      >
        Back
      </Button>
      <Form.Item
        name="authCode"
        rules={[
          { required: true, message: "Please input your Authentication Code!" },
        ]}
      >
        <Input
          value={authCode}
          onChange={(e) => setAuthCode(e.target.value)}
          type="number"
          placeholder="Authentication Code"
        />
      </Form.Item>

      <Form.Item style={{ display: "flex", justifyContent: "right" }}>
        <div
          style={{
            color: "#0A8FDC",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={() => {
            Modal.info({
              title: "Cannot Login?",
              content: (
                <>
                  Please click the back button and relogin. A code will be sent
                  to you again. If you still cannot access it please mail to{" "}
                  <a href="mailto:sustrax@carbonfootprint.com?subject=&body=">
                    <b>sustrax@carbonfootprint.com</b>
                  </a>
                </>
              ),
            });
          }}
        >
          Didn't get an email?
        </div>
      </Form.Item>

      <Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          CONFIRM
        </Button>
      </Form.Item>
    </Form>
  );
  const signUpForm = (
    <Form
      name="registration-form"
      style={{ padding: "20px" }}
      initialValues={{ remember: true }}
      onFinish={onFinishSignUp}
    >
      <Typography.Title level={3}>Sign Up</Typography.Title>
      <Form.Item
        name="companyName"
        rules={[{ required: true, message: "Please input your Company Name!" }]}
      >
        <Input placeholder="Company Name" />
      </Form.Item>
      <Form.Item
        name="country"
        style={{ width: "100%" }}
        rules={[{ required: true, message: "Please input your Country!" }]}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder={"Select Country"}
        >
          {getCountries().map((country) => {
            return (
              <Select.Option key={country} value={country}>
                {country}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="firstName"
        rules={[{ required: true, message: "Please input your First Name!" }]}
      >
        <Input placeholder="First Name" />
      </Form.Item>

      <Form.Item
        name="lastName"
        rules={[{ required: true, message: "Please input your Last Name!" }]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <Form.Item
        name="phone"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input your Phone Number!",
        //   },
        // ]}
      >
        <Input placeholder="Phone Number" />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your Email!",
          },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          REGISTER
        </Button>
      </Form.Item>
      <a
        style={{ display: "flex", justifyContent: "flex-end" }}
        onClick={() => setMode("simple")}
      >
        or login
      </a>
    </Form>
  );
  return (
    <div className="login-container">
      <div className="login-container-inner">
        <div className="login-form">
          <Logo className="login-logo" />
          {mode === "simple"
            ? loginForm
            : mode === "register"
            ? signUpForm
            : authForm}
        </div>
        <div className="login-content">
          <LoginContent />
        </div>
      </div>
    </div>
  );
};
export default Login;
