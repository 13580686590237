import { CheckCircleTwoTone, DeleteOutlined, EditTwoTone, WarningTwoTone, InfoOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Space, Table, Typography, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { T1 } from "../../appconfig/texts";
import Loading from "../../components/loading";
import EllipsisText from "../../components/text/EllipsisText";
import formatEmissions, { totalEmissions } from "../../components/text/resultEmissions";
import { findFormTexts, findSite, firstCompany, firstSite, openSites } from "../../helper/attribute";
import { exceldateformat, timeFormat } from "../../helper/time";
import { fixDecimals, intersection, removeIndexes, reverseObj } from "../../helper/wodash";
import { findForm } from "../../static/formRoutes";
import { getGHGTitle } from "../../static/ghg.static";
import { routePath } from "../../static/routes.static";
import InputDataPage from "../form/inputdata";
import DataForm from "../form";
import { Pair } from "../form/reviewpage";
import { isNumber } from "lodash";
import { morphResults } from "../results/resultsCalcs";
import { getCurrentPlan } from "../../helper/plans";

let EDITROWINDEX = -1;
let DATABEFOREEDIT = undefined;
let MAXROWS = 2000;

function arraysToObjects(keysArray, dataArrays) {
  return dataArrays.map((dataArray) => {
    return keysArray.reduce((obj, key, index) => {
      obj[key] = dataArray[index];
      return obj;
    }, {});
  });
}

function manageMetrics(row, companyMetrics) {
  let transformedRow = {};

  for (const [key, value] of Object.entries(row)) {
    if (key.startsWith("Metric:")) {
      const metricName = key.split(":")[1].trim();
      const metricIndex = companyMetrics.findIndex((metric) => metric.title === metricName);
      if (metricIndex !== -1) {
        transformedRow[`m_${metricIndex + 1}`] = value; // Index starts at 1
      } else {
        transformedRow[key] = value;
      }
    } else {
      transformedRow[key] = value;
    }
  }

  return transformedRow;
}

export default function ReviewPage({
  data,
  setData,
  errorRows,
  settings = { type: "Default" }, // IF TYPE IS MONTHLY, TRANSFORM THE SHEET
}) {
  const form = findForm(data.name);
  const [modalStates, setModalStates] = useState({ edit: false });
  const [modalValues, setModalValues] = useState({ edit: {} });
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [companyMetrics, setCompanyMetrics] = useState(firstCompany()?.inputMetrics?.filter((metric) => metric.enabled) ?? []);
  const navigate = useNavigate();

  console.log(companyMetrics, firstCompany()?.inputMetrics);
  const file = data.file;
  const formFields = {};
  findFormTexts(data.name, "forms")?.forEach((obj) => (formFields[obj.name] = obj.title));
  useEffect(() => {
    try {
      InitProcedure();
    } catch (err) {}
  }, []);

  // useEffect(() => {
  //   console.log(totalEmissions(data?.rows, true));
  //   if (totalEmissions(data?.rows, true)?.total_emissions == 0) {
  //     setData({ ...data, zeroEmissionsError: true });
  //   } else {
  //     setData({ ...data, zeroEmissionsError: false });
  //   }
  // }, [data?.rows]);
  const InitProcedure = () => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    let rows = [],
      errorRows = [];
    reader.onload = async (event) => {
      rows = event.target.result;
      const workbook = XLSX.read(rows, { type: "array" });
      let sheetName = settings.sheetName;

      const sheet = workbook.Sheets[sheetName];
      let initialData = {};
      try {
        const config = workbook.Sheets["Config"];
        if (config) {
          const configdata = XLSX.utils.sheet_to_json(config, {
            header: 1,
            raw: true,
          });
          function arrayToObject(arr) {
            return arr.reduce((obj, [key, value]) => {
              obj[key] = value;
              return obj;
            }, {});
          }
          initialData = arrayToObject(configdata);
        }
      } catch (err) {}
      let fields = reverseObj(formFields);

      rows = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        defval: null,
        blankrows: false,
      });

      if (rows?.length > MAXROWS) {
        // MAX LENGTH CHECK
        message.warning("Maximum number of rows are " + MAXROWS);
        rows = rows.slice(0, MAXROWS + 1);
      }
      data.excelRows = [...rows.slice(1, rows?.length)];
      rows[0] = rows[0].map((key) => {
        if (fields[key]) {
          return fields[key];
        } else {
          return key;
        }
      });
      rows = arraysToObjects(rows[0], rows.slice(1, rows?.length));
      console.log("ROWS IN VIEW", rows);
      if (Array.isArray(rows)) {
        rows = rows.map((row) => {
          return { ...row, bulk_uploaded: true };
        });
      }

      if (settings.type === "Monthly") {
        console.log("Applying monthly settings");
        // spread rows reading months
        const newRows = [];
        const months = Array.from({ length: 12 }, (_, index) => {
          return moment().month(index).format("MMM");
        });
        function getLastDayOfMonth(year, month) {
          const lastDayOfMonth = moment(`${year}-${month}-01`, "YYYY-MMM-DD").endOf("month").format(timeFormat);
          return lastDayOfMonth;
        }
        rows?.forEach((row) => {
          console.log(row);
          const year = row.Year;
          months?.forEach((month) => {
            const em = row[month];
            if (typeof em === "number") {
              console.log(month, em);
              const transformed_date = getLastDayOfMonth(year, month);
              const newRow = { ...row };
              newRow["date"] = transformed_date;
              const { amount_key } = form;
              newRow[amount_key ?? "amount"] = em; // main key is?
              newRows.push(newRow);
            }
          });
        });
        rows = newRows;
        console.log(rows);
      }

      if (!rows.length) rows = [];

      // Adding sites

      console.log(rows);
      rows = rows.map((row) => {
        const siteId = openSites().find((site) => site.title === row["Site"])?.id || firstSite().id;
        delete row["Site"];
        return {
          ...row,
          siteId,
          date: settings.type === "Monthly" ? row?.date : exceldateformat(row?.date),
        };
      });

      rows = await Promise.all(
        rows.map(async (beforerow, index) => {
          let row = beforerow;
          let packed = row;
          try {
            if (row.date === "Invalid date") throw "Invalid Date Format";
            row = { ...row, ...initialData };
            // managing metrics

            row = manageMetrics(row, companyMetrics);

            packed = await form.pack({ ...row });
            if (isNaN(packed.result)) throw "Result not calculated";
          } catch (err) {
            packed = { ...row, err };
            errorRows.push(beforerow);
          }
          // console.log("beforerow", beforerow);
          // console.log("after", packed);
          return packed;
        })
      );
      rows = rows.map((row, i) => ({ ...row, key: Number(i + 1) + ")" }));
      DATABEFOREEDIT = JSON.parse(JSON.stringify({ ...data, rows }));
      // ERROR ROWS REMOVAL-1: FUZZY MATCH
      setData({
        ...data,
        rows: await Promise.all(
          rows.map(async (row) => {
            try {
              if (row.err) {
                row = await form.pack(row, true);
                delete row.err;
                return row;
              }
            } catch (err) {
            } finally {
              return row;
            }
          })
        ),
      });
    };
  };
  const handleEdit = (_, index) => {
    //Edit rows before uploading
    EDITROWINDEX = data?.rows.findIndex((row) => row.key === _.key);
    setModalValues({ ...modalValues, edit: _ });
    setModalStates({ ...modalStates, edit: true });
  };
  const handleDisplayCompleteInfo = (_, index) => {
    setDisplayInfoModal(_);
  };
  const handleDeleteRows = (indexes) => {
    setSelectedRowKeys([]);
    const newData = {
      ...data,
      rows: removeIndexes(
        data.rows,
        indexes.map((i) => parseInt(i))
      ),
      excelRows: removeIndexes(
        data.excelRows,
        indexes.map((i) => parseInt(i))
      ),
    };
    setData(newData);
    DATABEFOREEDIT = JSON.parse(JSON.stringify(newData)); // because index is going to change
  };
  const columns = [
    {
      title: "No.",
      width: 70,
      render: (item, record) => {
        return item.key;
      },
    },
    {
      title: "Site",
      width: 130,
      render: (item, record, index) => {
        return findSite(record.siteId).title;
      },
    },
    ...findFormTexts(data.name, "tables", true).map((field) => {
      const obj = {
        title: field.title,
        width: 190,
        dataIndex: field.name,
        render: (value, record, index) => {
          return (
            <div>
              {DATABEFOREEDIT.rows[index][field.name] !== value && field.name !== "ghg" && !(getCurrentPlan() === "ma" && field.name === "date") && (
                <div style={{ textDecoration: "line-through", color: "grey" }}>{DATABEFOREEDIT.rows[index][field.name]}</div>
              )}
              <div className={intersection(record?.err?.split(" "), obj.title?.split(" ")).length && "antd-cell-err"}>
                {field.name.includes("ghg") ? value : isNumber(value) ? fixDecimals(value) : value}
              </div>
            </div>
          );
        },
      };
      return obj;
    }),
    {
      title: "Result",
      width: 160,
      render: (_, record, index) => {
        if (!_.result && _.result !== 0)
          return (
            <small style={{ color: "red" }}>
              Result not calculated. Please{" "}
              <a onClick={() => handleEdit(_, index)} style={{ color: "red", textDecoration: "underline" }}>
                click here
              </a>
              .
            </small>
          );
        if ("result" in _ && _.result !== NaN && _.result !== undefined) {
          return (
            <Space>
              {/* <CheckSquareTwoTone twoToneColor={"skyblue"} />{" "} */}
              {formatEmissions(_.result)}
              <CheckCircleTwoTone twoToneColor={"green"} />
            </Space>
          );
        } else {
          return (
            <Space>
              <WarningTwoTone twoToneColor={"red"} />
              <small>{_.err || "Calculation error! "}</small>
            </Space>
          );
        }
      },
    },
    {
      title: "Comment",
      width: 200,
      dataIndex: "comment",
      render: (_) => (
        <small>
          <EllipsisText maxLength={20} text={_} />
        </small>
      ),
    },
    {
      title: "Data Link",
      width: 140,
      dataIndex: "dataLink",
      render: (_) => <small>{_}</small>,
    },
    {
      title: "Data Tag",
      width: 140,
      dataIndex: "resulttag",
      render: (_) => <small>{_}</small>,
    },
    {
      title: "Cost",
      width: 140,
      dataIndex: "cost",
      render: (_) => <small>{_}</small>,
    },
    ...companyMetrics?.map((metric, index) => {
      const dataIndex = "m_" + (index + 1);
      return {
        title: metric.title,
        dataIndex,
        key: dataIndex,
        width: 140,
      };
    }),
    {
      title: "Options",
      fixed: "right",
      width: 150,
      render: (_, record, index) => {
        return (
          <Space>
            <Button size="small" type="primary" style={{ background: "grey" }} onClick={() => handleDisplayCompleteInfo(_, index)}>
              <InfoOutlined />
            </Button>
            <Button type="primary" size="small" onClick={() => handleEdit(_, index)}>
              <EditTwoTone twoToneColor={"white"} />
            </Button>
            <Button type="primary" size="small" style={{ background: "firebrick" }} onClick={() => handleDeleteRows([index])}>
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];
  const handleExport = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    console.log(data);
    const rows = data.excelRows.filter((_, index) => data.rows[index]?.err);
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(rows);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a buffer
    const buffer = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "output.xlsx";

    // Programmatically trigger the download
    link.click();

    // Clean up the temporary anchor element
    URL.revokeObjectURL(link.href);
  };
  if (!data?.rows)
    return (
      <Loading
        title={
          <div>
            Generating Data <span id="done-ids"></span>. This might take a while.
          </div>
        }
      />
    );

  return (
    <div>
      <Typography.Title level={3}>{form.title} Bulk Upload</Typography.Title>
      <p>Please review data below before uploading.</p>
      {errorRows() ? (
        <Alert
          style={{ marginBottom: 10 }}
          showIcon
          type="error"
          message={<>{errorRows() + " out of " + data?.rows?.length + " rows have error."}</>}
          description={"Please correct them before uploading."}
        />
      ) : null}

      {data.zeroEmissionsError ? <Alert type="warning" showIcon message="Zero Emissions" description="Total emissions are calculated to be zero. Please recheck the table before uploading. " /> : ""}

      {
        <Table
          rowKey={(record, i) => i + "-row"}
          pagination={false}
          title={() => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ border: "", display: "flex", alignItems: "center" }}>
                ℹ️.{"  "}
                {totalEmissions(data?.rows)}{" "}
              </div>
              <Space>
                <Button type="primary" onClick={handleExport}>
                  <T1 pos="bulk.downErrRowsBtn" />
                </Button>
                {selectedRowKeys.length ? (
                  <Button type="primary" style={{ background: "firebrick" }} onClick={() => handleDeleteRows(selectedRowKeys.map((key) => parseInt(key?.replace(")", ""))))}>
                    Delete {selectedRowKeys.length} rows
                  </Button>
                ) : null}
              </Space>
            </div>
          )}
          dataSource={morphResults(data?.rows)}
          rowClassName={(record, index) => (record.err ? "antd-table-row-red" : DATABEFOREEDIT?.rows[index]?.err && record.result && "antd-table-row-warning")}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
              console.log(selectedRowKeys);
            },
          }}
          scroll={{
            x: 1200,
          }}
        />
      }
      {/**MODALS */}
      <Modal
        destroyOnClose
        title={"Edit Row"}
        width={1000}
        okText="Update"
        open={modalStates.edit}
        onCancel={() => setModalStates({ ...modalStates, edit: false })}
        onOk={async () => {
          try {
            data.rows[EDITROWINDEX] = {
              ...(await form.pack(modalValues.edit)),
            };
            delete data.rows[EDITROWINDEX]["err"];
            const newData = JSON.parse(JSON.stringify(data));
            newData.file = data.file;
            setData(newData);

            setModalStates({ edit: false });
          } catch (err) {
            message.error(err);
          }
        }}
      >
        <>
          {/* <ImageCurrentRow /> */}
          <InputDataPage
            data={{ ...modalValues.edit, name: form.name, siteId: data.siteId }}
            setData={(newData) => setModalValues({ ...modalValues, edit: newData })}
            isBulkPage={true}
            bulkPageErr={modalValues.edit.err}
          />
        </>
      </Modal>
      {displayInfoModal && (
        <Modal open={displayInfoModal} onCancel={() => setDisplayInfoModal(false)} onOk={() => setDisplayInfoModal(false)}>
          <>
            <Typography.Title level={3}>Complete information</Typography.Title>
            {findFormTexts(form.name, "reviewpages", true).map((textObj, i) => (
              <Pair
                key={i + "pair"}
                name={textObj.name}
                option={textObj.title}
                value={textObj.name.includes("ghg") ? getGHGTitle(displayInfoModal[textObj.name]) : displayInfoModal[textObj.name]}
                formname={displayInfoModal.name}
                description={textObj.desc}
              />
            ))}
          </>
        </Modal>
      )}
    </div>
  );
}
