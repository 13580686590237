import { findSite } from "./attribute";

export const scope3overwrite = (results) => {
  // 1. scope3 overwrite
  if (Array.isArray(results)) {
    results = results.map((result) => {
      const site = findSite(result.siteId);
      if (site) {
        if (site._overwrite_ghg) {
          if ([1, 2, 4, 5].includes(result.ghg)) result.ghg = site._overwrite_ghg;
        }
      }
      return result;
    });
  }
  return results;
};
