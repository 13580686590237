import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Affix, Button, Space, Table } from "antd";
import React, { useState } from "react";
const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
const DNDTable = ({
  dataSource: data,
  showUpdateButton = true,
  columns,
  rowKey,
  onUpdate,
  onDirectUpdate,
  ...more
}) => {
  const [dataSource, setDataSource] = useState(
    JSON.parse(JSON.stringify(data))
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const updater = (previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      };
      const val = updater(dataSource);
      if (onUpdate) setDataSource(val);
      if (onDirectUpdate) onDirectUpdate(val);
    }
  };
  const handleCancel = () => {
    setDataSource(JSON.parse(JSON.stringify(data)));
  };
  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={dataSource.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        {showUpdateButton &&
          JSON.stringify(data) !== JSON.stringify(dataSource) && (
            <Affix offsetTop={0}>
              {
                <Space
                  style={{
                    padding: 10,
                    position: "sticky",
                    top: 0,
                    background: "white",
                    width: "100%",
                  }}
                >
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    loading={more.loading}
                    disabled={
                      JSON.stringify(data) === JSON.stringify(dataSource)
                    }
                    type="primary"
                    onClick={() => {
                      onUpdate(dataSource);
                    }}
                  >
                    Update Sorting
                  </Button>
                </Space>
              }
            </Affix>
          )}
        <Table
          locale={{}}
          style={{ zIndex: 0 }}
          pagination={false}
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey={"key"}
          columns={[{ key: "sort", width: 20, fixed: "left" }, ...columns]}
          dataSource={dataSource}
          {...more}
        />
      </SortableContext>
    </DndContext>
  );
};
export default DNDTable;
