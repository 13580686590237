import { Button, Divider, Typography } from "antd";
import React, { useState } from "react";
import { fetchResults } from "../../../firebase/data/get";
import { catcher } from "../../../firebase/util";
import { allRoutes } from "../../../routes";

export default function OtherSuperAdminSettings() {
  const [loading, setLoading] = useState(false);
  const handleLoadAllData = () => {
    const formKey = window.prompt("Form Key? (enter nothing to load all)");
    const planKey = window.prompt("Plan key? (e.g. pro, ma etc) (enter nothing to load all)");
    const confirmation = window.confirm("Load All Users Data for plan: " + planKey);
    if (confirmation) {
      catcher(
        async () => {
          await fetchResults(formKey, 30, planKey);
        },
        { setLoading }
      );
    }
  };
  return (
    <div>
      <Typography.Title level={4}>Options</Typography.Title>
      <Button loading={loading} type="primary" onClick={handleLoadAllData}>
        Load Results (⚠️ it will load too much data)
      </Button>
      <Divider></Divider>
      <Typography.Title level={4}>Hidden Links</Typography.Title>
      1. download_raw_user_info: displays info regarding downloading user info <br></br>2. consultant_user_verification: user consultant
      <Typography.Title level={5}>All Links</Typography.Title>
      {allRoutes.map((r) => {
        return (
          <div>
            <a href={r.path} target="_blank">{r.path}</a>
          </div>
        );
      })}
    </div>
  );
}
