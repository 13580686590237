import React, { useEffect } from "react";
import { Input, Checkbox, Typography } from "antd";

export default function AdditionalDataMetrics({ data, setData }) {
    if (!data.inputMetrics) data.inputMetrics = [{}, {}, {}]
    const changeMetricData = (index, prop, val) => {
        const updatedMetrics = [...data.inputMetrics];
        updatedMetrics[index][prop] = val;
        setData({ ...data, inputMetrics: updatedMetrics });
    };

    const toggleNumType = (index, checked) => {
        changeMetricData(index, "type", checked ? "num" : "default");
    };

    const toggleEnabled = (index, checked) => {
        changeMetricData(index, "enabled", checked);
    };

    return (
        <div style={{ marginLeft: 10 }}>
            <Typography.Title level={4}>Additional Data Metrics</Typography.Title>
            {data.inputMetrics.map((inp, index) => (
                <div key={index} style={{ width: "700px", display: "flex", gap: 10, alignItems: "end" }}>
                    <Input
                        bordered={false}
                        placeholder="Title"
                        value={inp.title}
                        onChange={(e) => changeMetricData(index, "title", e.target.value)}
                    />
                    <Checkbox
                        style={{ width: "240px" }}
                        checked={inp.type === "num"}
                        onChange={(e) => toggleNumType(index, e.target.checked)}
                    >
                        Numbers only
                    </Checkbox>
                    <Checkbox
                        checked={inp.enabled}
                        onChange={(e) => toggleEnabled(index, e.target.checked)}
                    >
                        Enabled
                    </Checkbox>
                </div>
            ))}
        </div>
    );
}
