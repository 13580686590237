import Sites from "../pages/analysis/site";
import Months from "../pages/analysis/month";
import Scope from "../pages/analysis/scope";
import Tags from "../pages/analysis/tags";
import Years from "../pages/analysis/year";
import GHGCategory from "../pages/analysis/ghgcategory";
import { plans, planValue } from "../helper/plans";
import ResultTags from "../pages/analysis/resulttags";

const analysisConfig = {
  Years: {
    component: Years,
    planFilter: "canViewYearsAnalysis",
  },
  Months: {
    component: Months,
    planFilter: "canViewMonthsAnalysis",
  },
  Sites: {
    component: Sites,
    planFilter: "canViewSitesAnalysis",
  },
  "GHG Category": {
    component: GHGCategory,
    planFilter: "canViewGHGAnalysis",
  },
  "Data Tags": {
    component: ResultTags,
    planFilter: "canViewResultTagsAnalysis",
  },
  "Site Tags": {
    component: Tags,
    planFilter: "canViewTagsAnalysis",
  },
};

const getAnalysisRouteLabels = () => {
  Object.keys(analysisConfig).forEach((key) => {
    if (!planValue(analysisConfig[key].planFilter)) {
      delete analysisConfig[key];
    }
  });
  return Object.keys(analysisConfig);
};

export { getAnalysisRouteLabels };
export default analysisConfig;
