import { Radio, theme } from "antd";
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/features/appSlice";

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};

  .label {
    font-weight: 700;
    color: ${({ theme }) => theme.colorText};
    min-width: ${({ minWidth }) => minWidth || "100px"};
  }

  .radio-group {
    display: flex;
    gap: 5px;
    flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  }

  .radio-button {
    cursor: pointer;
    padding: ${({ optionSize }) => optionSize || "4px 10px"};
    color: ${({ theme }) => theme.colorText};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.backgroundColor};
    border: 1px solid ${({ theme }) => theme.borderColor};
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.hoverBackgroundColor};
      color: ${({ theme }) => theme.hoverColor};
    }
  }

  .selected {
    border: 1.5px solid ${({ theme }) => theme.selectedBorderColor};
    color: ${({ theme }) => theme.selectedColor};
    font-weight: 500;
    background-color: ${({ theme }) => theme.selectedBackgroundColor};

    &:hover {
      color: ${({ theme }) => theme.selectedHoverColor};
      background-color: ${({ theme }) => theme.selectedHoverBackgroundColor};
    }
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export default function Select1({
  options,
  hideIfOneOptionOnly,
  value,
  setValue,
  title = "Select One",
  description,
  minWidth = "100px",
  disabled = [],
  wrapOptions = true,
  optionSize = "4px 10px",
  showLabel = true,
}) {
  const themeg = useSelector(selectTheme);

  if (hideIfOneOptionOnly && options.length <= 1) {
    return null;
  }
  if (typeof options[0] !== "object") {
    options = options.map((option) => ({ key: option, value: option }));
  }

  const theme = {
    colorText: themeg.isDarkMode ? "#ffffff" : "#000000",
    backgroundColor: themeg.isDarkMode ? "#333333" : "#ffffff",
    borderColor: themeg.isDarkMode ? "#4CAF50" : "#8BC34A",
    hoverBackgroundColor: themeg.isDarkMode ? "#2E7D32" : "lightgrey",
    hoverColor: themeg.isDarkMode ? "#ffffff" : "#000000",
    selectedBorderColor: themeg.isDarkMode ? "#66BB6A" : "#388E3C",
    selectedColor: themeg.isDarkMode ? "#ffffff" : "black",
    selectedBackgroundColor: themeg.isDarkMode ? "#1B5E20" : "#A5D6A7",
    selectedHoverColor: themeg.isDarkMode ? "#ffffff" : "#ffffff",
    selectedHoverBackgroundColor: themeg.isDarkMode ? "#004D40" : "#81C784",
  };

  return (
    <>
      <StyledSelect theme={theme} wrap={wrapOptions} optionSize={optionSize} minWidth={minWidth}>
        {showLabel && <p className="label">{title}</p>}
        <Radio.Group size="middle" value={value} onChange={(e) => setValue(e.target.value)} className="radio-group">
          {options.map((option) => (
            <Radio.Button
              key={option.key}
              value={option.value}
              disabled={disabled.includes(option.value)}
              className={value === option.value ? "radio-button selected" : "radio-button"}
              style={{
                backgroundColor: disabled.includes(option.value) ? "#e0e0e0" : "",
                color: value === option.value && themeg.isDarkMode ? "white" : "",
                borderRadius:"1px",
                minWidth:"100px"
              }}
            >
              {option.key}
            </Radio.Button>
          ))}
        </Radio.Group>
      </StyledSelect>
      {description && <div style={{ fontSize: "12px", color: "grey" }}>{description}</div>}
    </>
  );
}
