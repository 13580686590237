import React from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import { filterFactors } from "../../../helper/emissionfactors";
import { genPut } from "../helper/create";

export const INPUTS = ({ data, setData }) => {
  const categoryOptions = filterFactors({ form: "fuel" }, "Level 2");
  const typeOptions = filterFactors({ "Level 2": data.category, form: "fuel" }, "Level 3");
  const uomOptions = filterFactors({ "Level 3": data.type, "Level 2": data.category, form: "fuel" }, "UOM").sort();

  return [
    {
      elem: DateInput,
      key: "date",
      value: data.date,
    },
    {
      elem: SelectSearch,
      key: "category",
      value: data.category,
      setValue: (category) => setData({ ...data, category, type: null, uom: null }),
      options: categoryOptions,
    },
    {
      elem: SelectSearch,
      key: "type",
      value: data.type,
      hidden: !data?.category,
      options: typeOptions,
      set: { uom: null },
    },
    {
      elem: SelectSearch,
      key: "uom",
      hidden: !data?.type,
      value: data.uom,
      options: uomOptions,
    },
    {
      elem: NumberInput,
      key: "amount",
      value: data.amount,
    },
  ];
};

export default function Fuel({ data, setData }) {
  const formname = "fuel";

  console.log(data);

  const inputs = INPUTS({ data, setData });

  return (
    <div>
      {inputs.map((input) => genPut(input, formname, data, setData))}
    </div>
  );
}
