import {
  CloseCircleFilled,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Modal, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { T1 } from "../../../appconfig/texts";
import Loading from "../../../components/loading";
import UserAvatar from "../../../components/User/avatar";
import { updateUser } from "../../../firebase/user/update";
import { except } from "../../../firebase/util";
import { findAdminsOfSite } from "../../../helper/attribute";
import AddAdminSite from "./admin_add";
export default function SiteAdmins({ _ }) {
  let title = (
    <Typography.Title level={2}>
      <T1 pos="site.siteAdmins.text" />
    </Typography.Title>
  );

  const navigate = useNavigate();
  const [modals, setModals] = useState({ add: false, update: false });
  const setModal = (whichOne, status = new Date().getTime()) => {
    modals[whichOne] = status;
    setModals({ ...modals });
  };

  const handleDissmissAdmin = (user) => {
    Modal.confirm({
      onOk: () =>
        except(async () => {
          let admins = user.admin_of_sites;
          if (!admins) admins = [];
          await updateUser(user.id, {
            admin_of_sites: admins.filter((siteId) => siteId !== _.id),
          });
          navigate("/sites");
        }),
      title: `Dismiss as admin`,
      content: `Do you want to dismiss ${user.firstName} as admin of ${_.title}?`,
    });
  };

  let columns = [
    {
      title: <T1 pos="site.siteAdmins.table.name" />,
      render: (_) => (
        <Space>
          <UserAvatar _={_} />
          <span style={{ fontWeight: 600 }}>
            {_.firstName + " " + _.lastName}
          </span>
        </Space>
      ),
      width: 120,
    },
    {
      title: <T1 pos="site.siteAdmins.table.contact" />,
      render: (_) => (
        <Space direction="vertical">
          <Space>
            <MailOutlined />
            <a href={`mailto:${_.email}`} target={"_blank"} rel="noreferrer">
              {_.email}
            </a>
          </Space>
          {_?.phone && (
            <Space>
              <PhoneOutlined />
              <a href={`tels:${_.phone}`} target={"_blank"} rel="noreferrer">
                {_.phone}
              </a>
            </Space>
          )}
        </Space>
      ),
      width: 120,
    },
    {
      title: "",
      render: (_) => (
        <Space
          style={{ cursor: "pointer", fontSize: 20 }}
          className="antRowShowOnHover"
        >
          <CloseCircleFilled
            onClick={() => handleDissmissAdmin(_)}
            style={{ color: "darkred" }}
          />
        </Space>
      ),
      width: 120,
    },
  ];
  const users = findAdminsOfSite(_);
  if (!users) return <Loading />;
  return (
    <>
      <Space>
        <h2>{title}</h2>
        <Button onClick={() => setModal("add")}>+ Add</Button>
      </Space>
      {/*Table */}
      <Table dataSource={users} columns={columns} rowKey={"id"} />
      {/*Modals*/}
      <AddAdminSite
        key={modals.add}
        visible={modals.add}
        setVisible={(v) => setModal("add", v)}
        _={_}
      />
    </>
  );
}
