import React, { useEffect } from "react";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import { findFormText, sortForm } from "../../../helper/attribute";
import { findForm } from "../../../static/formRoutes";
import { filterFactors } from "../../../helper/emissionfactors";
import SelectSearch from "../../../components/select/SearchSelect";
import { PercentageOutlined } from "@ant-design/icons";
import { genPut } from "../helper/create";

export const INPUTS = (data, setData) => {
  const form = findForm(data.name);
  const ids = form.id_range();
  return sortForm(
    [
      {
        elem: DateInput,
        key: "date",
      },
      {
        elem: NumberInput,
        key: "amount",
      },
      {
        elem: SelectSearch,
        key: "uom",
        options: filterFactors(undefined, "UOM", true, ids),
      },
      {
        elem: NumberInput,
        key: "waste%",
        uom: "%",
        // leftElem: <PercentageOutlined />,
        max: 100,
        min: 0,
        displayInfo: false,
      },
    ],
    data.name
  );
};

export default function Water({ data, setData }) {
  const formname = data.name;
  useEffect(() => {
    setData({ ...data, country: data.country || null });
  }, []);

  return (
    <div>
      {INPUTS(data, setData).map((input) =>
        genPut(input, formname, data, setData)
      )}
    </div>
  );
}
