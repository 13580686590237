import { DownloadOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Divider, Space } from "antd";
import React, { useEffect } from "react";
import airportData from "../../../assets/airportData.json";
import AntDrop from "../../../components/input/antddropdown";
import DateInput from "../../../components/input/date";
import NumberInput from "../../../components/input/number";
import SelectSearch from "../../../components/select/SearchSelect";
import Select1 from "../../../components/select/select1";
import {
  AirportsDistance,
  FlightType,
  questions1,
} from "../../../helper/airport";
import { findFormText } from "../../../helper/attribute";
import { filterFactors } from "../../../helper/emissionfactors";
import { genPut } from "../helper/create";
import { fixDecimals } from "../../../helper/wodash";

const airportOptionFilterer = (input, option) => {
  return option.children
    .toLowerCase()
    .indexOf(input.toLowerCase()) >= 0;
};


export const ExcelExport = (props) => {
  const handleOnChange = (q1) => {
    props.downloadTemplate({ ...props.form, q1 }, { q1 });
  };
  return (
    <AntDrop options={questions1} onChange={handleOnChange}>
      <Button type="link">
        Download Template <DownloadOutlined />
      </Button>
    </AntDrop>
  );
};

export const getFlightPropsBasedOnAirports = (data) => {
  let totalDistance = 0;
  let flightType = "";

  if (data.via_airport) {
    // Calculate distance from departure to via airport
    const dep_to_via = {
        departure_airport: data.departure_airport,
        destination_airport: data.via_airport,
      },
      via_to_dest = {
        departure_airport: data.via_airport,
        destination_airport: data.destination_airport,
      };
    totalDistance += AirportsDistance(dep_to_via);
    flightType += FlightType(dep_to_via) + ", ";

    // Calculate distance from via airport to destination airport
    totalDistance += AirportsDistance(via_to_dest);
    flightType += FlightType(via_to_dest);
  } else {
    // Calculate distance directly from departure to destination
    totalDistance = AirportsDistance({
      departure_airport: data.departure_airport,
      destination_airport: data.destination_airport,
    });

    // Flight type for direct flights
    flightType = FlightType(data);
  }
  return { flightType, totalDistance };
};

export const INPUTS = (data, setData) => {
  const formname = "flight";
  const { totalDistance, flightType } = getFlightPropsBasedOnAirports(data);
  return [
    {
      key: "date",
      elem: DateInput,
    },
    {
      key: "q1",
      elem: Select1,
      options: questions1,
      setValue: (q1) =>
        setData({
          ...data,
          q1,
          departure_airport: null,
          destination_airport: null,
          flight_class: null,
          distance: null,
          flight_type: null,
        }),
      excelShow: false,
    },
    {
      key: "departure_airport",
      elem: SelectSearch,
      options: airportData.map((x) => x["Search Name"]),
      hidden: data.q1 !== questions1[0],
      excelShow: data.q1 === questions1[0],
      dropdownMatchSelectWidth: 500,
      filterOption: airportOptionFilterer,
    },
    {
      key: "via_airport",
      elem: SelectSearch,
      allowClear: true,
      options: airportData.map((x) => x["Search Name"]),
      hidden: data.q1 !== questions1[0],
      excelShow: data.q1 === questions1[0],
      dropdownMatchSelectWidth: 500,
      filterOption: airportOptionFilterer,
    },
    {
      key: "destination_airport",
      elem: SelectSearch,
      options: airportData.map((x) => x["Search Name"]),
      hidden: data.q1 !== questions1[0],
      excelShow: data.q1 === questions1[0],
      dropdownMatchSelectWidth: 500,
      description: (
        <Space style={{ fontSize: "12px" }}>
          <InfoCircleTwoTone />
          Total {fixDecimals(totalDistance) + " km"}({flightType})
        </Space>
      ),
      filterOption: airportOptionFilterer,
    },
    {
      key: "distance",
      elem: NumberInput,
      hidden: data.q1 === questions1[0],
      excelShow: data.q1 !== questions1[0],
    },
    {
      key: "flight_type",
      elem: SelectSearch,
      options: filterFactors({ "Level 2": "Flights" }, "Level 3"),
      hidden: data.q1 === questions1[0],
      excelShow: data.q1 !== questions1[0],
    },
    {
      key: "flight_class",
      elem: SelectSearch,
      options: filterFactors(
        {
          "Level 3":
            data.q1 === questions1[0] ? FlightType(data) : data.flight_type,
          "Level 2": "Flights",
        },
        "Level 4"
      ),
    },
    {
      key: "passengers",
      elem: NumberInput,
      min: 1,
    },
    {
      key: "return_ticket",
      elem: Select1,
      options: ["Yes", "No"],
    },
  ];
};

export function AirportSelection({ data, setData }) {
  const formname = "flight";

  return (
    <>
      <SelectSearch
        dropdownMatchSelectWidth={500}
        title={findFormText(formname, "departure_airport")}
        description={findFormText(formname, "departure_airport", "desc")}
        value={data.departure_airport}
        setValue={(departure_airport) =>
          setData({ ...data, departure_airport })
        }
        options={airportData.map((x) => x["Search Name"])}
      />
      <SelectSearch
        dropdownMatchSelectWidth={500}
        title={findFormText(formname, "destination_airport")}
        description={findFormText(formname, "destination_airport", "desc")}
        value={data.destination_airport}
        setValue={(destination_airport) =>
          setData({ ...data, destination_airport })
        }
        options={airportData.map((x) => x["Search Name"])}
      />
      <Space>
        <InfoCircleTwoTone />
        <b>{findFormText(formname, "distance")}</b>
        {AirportsDistance(data).toFixed(2) + " km"} ({FlightType(data)})
      </Space>
    </>
  );
}

export default function Flight({ data, setData }) {
  useEffect(() => {
    setData({
      ...data,
      return_ticket: data.return_ticket ?? "Yes",
      q1: data.q1 ? data.q1 : questions1[0],
    });
  }, []);

  const formname = "flight";

  return (
    <>
      {INPUTS(data, setData, formname).map((input) => {
        return genPut(input, formname, data, setData);
      })}
    </>
  );
}