import React from "react";
import LoadingSpin from "react-loading-spin";
import { Skeleton } from "antd";

const Loading = ({ title = "Loading Data", quotes = false }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      height: "90vh",
      alignItems: "center",
      flexDirection: "column",
      gap: 10,
    }}
  >
    {quotes ? (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Skeleton.Avatar active size={64} shape="circle" />
        <Skeleton.Input active style={{ width: 200, margin: "20px 0" }} />
        <Skeleton.Input active style={{ width: 400, margin: "10px 0" }} />
        <Skeleton.Input active style={{ width: 400, margin: "10px 0" }} />
      </div>
    ) : (
      <LoadingSpin />
    )}
    <div>{title}</div>
  </div>
);

export default Loading;
