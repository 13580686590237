import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import texts, { T1 } from "../../../appconfig/texts";

import {
  designations,
  findCompanies,
  findSitesInCompanies,
  firstCompany,
  getCurrentUserDesignation,
} from "../../../helper/attribute";
import { selectResults } from "../../../redux/features/appSlice";
import Boxed from "./comp/boxed";
import ExportButton from "./comp/exportbutton";
import Heading from "./comp/heading";
import Options from "./comp/options";
import { createGraphComponent, createcols, tableProps } from "./config";

import moment from "moment";
import { getCurrentYear, timeFormat } from "../../../helper/time";
import SkeletonBarChart from "./charts/SkeletonBarChart";
import "./style.css";
import { getAnalysis } from "./workers/month";

const MonthAnalysis = ({ graphOnly = false }) => {
  const [years, setYears] = useState([2021, 2022, 2023, 2024]);
  const [options, setOptions] = useState({
    wtt: "Included",
    type: "Location",
    reporting: "All",
    year: getCurrentYear() ?? 2024,
    monthRange: "All Year",
    showMonthNames: "Yes",
    reportType: "Emissions",
    sites:
      getCurrentUserDesignation() !== designations.group_admin
        ? [findCompanies()[0]?.id]
        : findCompanies().map((c) => c?.id),
  });
  const [dataSource, setDataSource] = useState(undefined);
  const [cols, setCols] = useState([]);
  const [scopeChart, setScopeChart] = useState([]);
  let results = useSelector(selectResults);
  // calculate result
  const setResults = (message) => {
    if (typeof message === "object" && message?.data?.dataSource) {
      setDataSource(message.data.dataSource);
      setCols(message.data.moreInfo.cols);
      setScopeChart(message.data.moreInfo.scopechart);
    }
  };
  // useEffect(() => {
  //   try {
  //     function extractYears(data) {
  //       // Use a Set to collect unique years
  //       const yearSet = new Set(
  //         data.map((item) => moment(item.date, timeFormat).year())
  //       );

  //       // Convert the Set back to an array and sort it
  //       const uniqueYears = Array.from(yearSet).sort((a, b) => a - b);
  //       return uniqueYears;
  //     }
  //     const years = extractYears(results);
  //     if (years.length) setYears(years);
  //   } catch (err) {}
  // }, [results]);
  useEffect(() => {
    setDataSource(undefined);
    if (results && getAnalysis) {
      const sites = findSitesInCompanies(options.sites);
      results = results.filter((result) => sites.includes(result.siteId));
      setResults({ data: getAnalysis(results, options) });
    }
  }, [results, options]);
  const [graphFilters, setGraphFilters] = useState({ col: "01" });

  const columns = createcols(cols, dataSource, options);
  const graphComponent = createGraphComponent(
    scopeChart,
    graphFilters,
    setGraphFilters,
    cols,
    "fiscal Year Months",
    {
      xLabel: "Months",
      year: options?.year,
      showAllMonths: options?.year === "custom" ? false : true,
      reporting_year_starting_month:
        firstCompany()?.reporting_year_starting_month,
    }
  );
  if (graphOnly && !dataSource) return <SkeletonBarChart bars={12} />;
  if (graphOnly) return graphComponent;
  return (
    <div className="page">
      <Heading type={<T1 pos={"analysis.monthly.title"} />} />
      <Options options={options} setOptions={setOptions} years={years} />
      {graphComponent}
      <Boxed title={<T1 pos={"analysis.result.title"} />}>
        <ExportButton
          dataSource={dataSource}
          fileName={texts.analysis.monthly.export || "Export"}
          cols={cols}
          options={options}
        />
        <Table {...tableProps(dataSource, results, columns, cols)} />
      </Boxed>
    </div>
  );
};

export default MonthAnalysis;
